<template>

	<div
		class="landpage-search"
		:class="{ slim: mode === 'slim' }">

		<div style="background-color: rgba(255, 255, 255, 0.85); padding: 30px; border-radius: 10px; backdrop-filter: blur(5px); display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
			<h2 v-if="mode !== 'slim'" style="font-size: 26px; color: #384076; margin-bottom: 40px">
				Negocie aqui
			</h2>

			<div style="margin-top: 10px;">
				<label
					for="inputidentification"
					style="color: #7D7D7E; display: flex; flex-direction: column; ">Digite seu CPF ou CNPJ</label>

				<the-mask
					id="inputidentification"
					ref="inputIdentification"
					v-model="identification"
					pattern="[0-9]*"
					inputmode="numeric"
					:mask="['###.###.###-##', '##.###.###/####-##']"
					@keyup.native="validIdentification"
					></the-mask>
					<hr style="color: #DDDDDE">
			</div>
			<span
				v-show="messageError"
				class="message-invalid"
			>* CPF/CNPJ inválido </span>

			<button
				ref="botaoPesquisar"
				:style="estiloBotaoPesquisar"
				style="padding: 14px; margin-top: 40px;"
				@click="validIdentification"
				disabled
			>
				{{ textoDoBotaoDePesquisa }}
			</button>
		</div>
		
	</div>
</template>

<script>
import { mapState } from "vuex";
import { TheMask } from "vue-the-mask";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import Template from "@/views/admin/Template";

export default {
	name: "LandPageSearchSenff",

	components: { Template, TheMask },

	mixins: [globalMethods, mixins],

	props: {
		mode: {
			type: String,
			default: "",
			required: false
		}
	},

	data() {
		return {
			identification: "",
			messageError: false,
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria,
			portalCamec: (estado) => estado.portalCamec
		}),

		title() {
			var title = "";
			if (this.parceiroConfig.sessoes.header.campos != null && this.parceiroConfig.sessoes.header.campos.title != null) {
				var res = this.parceiroConfig?.sessoes?.header?.campos?.title.split(" ");
				var i;
				for (i = 0; i < res.length / 2; i++) {
					title += res[i] + " ";
				}
			}
			return title;
		},

		title2() {
			var title2 = "";
			if (this.parceiroConfig.sessoes.header.campos != null && this.parceiroConfig.sessoes.header.campos.title != null) {
				var res = this.parceiroConfig?.sessoes?.header?.campos?.title.split(" ");
				var i;
				if (res.length % 2 == 1) {
					for (i = res.length / 2 + 0.5; i < res.length; i++) {
						title2 += res[i] + " ";
					}
				} else {
					for (i = res.length / 2; i < res.length; i++) {
						title2 += res[i] + " ";
					}
				}
			}
			return title2;
		},

		temporaryColor() {
			return this.corPrimaria["--color"] != "" && this.corPrimaria["--color"] != "#000000" ? this.corPrimaria["--color"] : "#00c0fb";
		},

		textoDoBotaoDePesquisa() {
			return this.parceiroConfig.sessoes.customization.campos.landPage.textBotaoBuscar ?
					this.parceiroConfig.sessoes.customization.campos.landPage.textBotaoBuscar : "Buscar minhas dívidas ";
		},

		subtitulo() {
			return this.parceiro && this.parceiroConfig.sessoes.header.campos ? this.parceiroConfig.sessoes.header.campos.subtitle : "É seguro, rápido e online!"
		},

		corSubtitulo() {
			return {
				color: this.checkColors('subTitleColor') ? this.parceiroConfig.sessoes.header.campos.subTitleColor : '#a4a9ae'
			}
		},

		estiloTituloPrimario() {
			const retorno = {
				color: this.checkColors('firstTitleColor') ? this.parceiroConfig.sessoes.header.campos.firstTitleColor : '#FFF'
			}
			if (this.parceiroConfig.identificador == 'Senff') {
				retorno['background-color'] = 'rgba(255, 255, 255, 0.7)';
			}
			return retorno;
		},

		corTituloSecundario() {
			return {
				color: this.checkColors('secondTitleColor') ? this.parceiroConfig.sessoes.header.campos.secondTitleColor : this.temporaryColor
			}
		},

		tituloPrimario() {
			return this.title == "" ? "Negocie aqui" : this.title;
		},

		tituloSecundario() {
			return this.title2 == "" && this.parceiroConfig.emp_codigo != 350 ? " suas dívidas" : this.title2;
		},

		estiloInputCpf() {
			if (this.parceiroConfig.emp_codigo == 350) {
				return {
					"border-top-right-radius": "25px",
					"border-bottom-right-radius": "25px",
					"margin-bottom": "20px",
					...this.corPrimaria
				}
			} else {
				return this.corPrimaria;
			}
		},

		estiloBotaoPesquisar() {
			return this.corPrimaria;
		}
	},

	mounted() {
		this.$refs.inputIdentification.$el.focus();
		if (localStorage.getItem("showLGPDWarning") === null) {
			this.CHANGE_MESSAGE_MODAL({
				show: true,
				title: "Estamos em sintonia com a LGPD",
				message: `Fizemos mudanças dentro do nosso portal para respeitar a Lei Geral de Proteção de Dados e trazer mais segurança e transparência sobre o uso dos seus dados.`,
				styleMessage: "success"
			});
			localStorage.setItem("showLGPDWarning", true);
		}
	},

	methods: {
		searchIdentification() {
			if (this.removeMaskCharacters(this.identification).length === 11 || this.removeMaskCharacters(this.identification).length === 14) {
				this.$emit('proximo-passo', this.identification);
			} else {
				this.messageError = false;
			}
		},

		validIdentification(evento) {
			if (evento.keyCode == 13 || evento.type == 'click') {
				this.searchIdentification();
			}

			if (this.identification.length == 11) {
				var Soma;
				var Resto;
				Soma = 0;
				var strCPF = this.identification;

				if (strCPF == "00000000000") {
					this.messageError = true;
					return false;
				}

				for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;

				if (Resto != parseInt(strCPF.substring(9, 10))) {
					this.messageError = true;
					return false;
				}

				Soma = 0;
				for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;

				if (Resto != parseInt(strCPF.substring(10, 11))) {
					this.messageError = true;
					return this.messageError;
				}
				
				this.messageError = false;
				this.$refs.botaoPesquisar.removeAttribute('disabled');
			} else if (this.identification.length == 14) {
				var c = this.identification;
				var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

				if ((c = c.replace(/[^\d]/g, "")).length != 14) return false;

				if (/0{14}/.test(c)) return false;

				for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
				if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

				for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
				if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

				this.messageError = false;
				this.$refs.botaoPesquisar.removeAttribute('disabled');
			} else {
				this.$refs.botaoPesquisar.disabled = true;
			}
		},

		checkColors(especifico) {
			return this.parceiro && this.parceiroConfig.sessoes.header.campos && this.parceiroConfig.sessoes.header.campos[especifico];
		}
	}
};
</script>

<style scoped lang="scss">
.landpage-search {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: -100px;
}

.landpage-search h2 {
	font-size: 70px;
	text-align: center;
	font-family: "Montserrat-Bold";

	@media only screen and (max-width: 960px) {
		text-align: center;
		font-size: 50px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 40px;
	}
}

.primary-color {
	font-size: 70px;
	font-family: "Montserrat-Bold";

	@media only screen and (max-width: 960px) {
		font-size: 50px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 40px;
		display: block;
	}

	color: var(--color);
}

.landpage-search h5 {
	margin: 30px 0;
	font-size: 18px;
	color: var(--lightGrayColor);
	font-family: Cabin;

	@media only screen and (max-width: 700px) {
		font-size: 16px;
	}
}

.box-button {
	display: flex;
	align-items: center;
	box-sizing: border-box;

	@media only screen and (max-width: 700px) {
		flex-direction: column;
	}
}

button {
	background-color: #384076;
	transition: all 0.2s ease-in-out;
}

button:hover {
	background-color: var(--hoverColor);
	transition: all 0.2s ease-in-out;
}

.landpage-search input {
	height: 40px;
	width: 600px;
	background: transparent;
	border: none;
	border-radius: 7px;
	font-family: "Montserrat-Bold";
	font-size: 20px;
	color: #384076;

	@media only screen and (max-width: 700px) {
		width: 75vw;
		margin-bottom: 10px;
	}
}

.landpage-search.slim input {
	width: 250px;
}

.landpage-search.slim button {
	width: 200px;
}

.landpage-search input::placeholder {
	color: var(--lightGrayColor);
	font-size: 16px;
	font-family: Cabin;
}

.landpage-search button {
	margin-top: 20px;
	cursor: pointer;
	width: 300px;
	// height: 35px;
	border-radius: 7px;
	color: var(--whiteColor);
	border: 0;
	font-size: 16px;
	font-family: Cabin;

	@media only screen and (max-width: 700px) {
		width: 75vw;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		font-size: 16px;
	}
}

.landpage-search button i {
	font-size: 20px;
	margin-right: 5px;

	@media only screen and (max-width: 700px) {
		font-size: 18px;
	}
}

.message-invalid {
	margin-top: 10px;
	color: var(--dangerColor);
}

button:disabled {
	background-color: #cecece;
	cursor: not-allowed;
}

.modal {
	position: fixed;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.container-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 500px;
	height: 420px;
	margin: 0px auto;
	background-color: #fff;
	border-radius: 10px;
	padding: 80px 80px 35px;

	@media (max-width: 500px) {
		padding: 20px;
		width: 300px;
	}
}

.container-modal > p,
b > p {
	font-size: 24px;
	text-align: center;
	color: #384076;

	@media (max-width: 500px) {
		font-size: 20px;
	}
}

b {
	font-size: 24px;
	font-weight: 900;
	@media (max-width: 500px) {
		font-size: 20px;
	}
}
</style>
