<template>
	<div class="page-birthday">
		<div class="box-login">
			<h3 :style="{ color: parceiroConfigCorSecundariaCorPrimaria }">Entrar</h3>
			<p>
				{{ mensagemEmbaixoDoTitulo }}
			</p>

			<form-fields
				:fields="fields"
				@save="next"
				@update:values="inputsValues = $event" ></form-fields>

			<div v-if="parceiroConfig.sessoes.customization == undefined ||
				 	   parceiroConfig.sessoes.customization.campos.confirmBirthday == undefined ||
					   parceiroConfig.sessoes.customization.campos.confirmBirthday.flgValidarTelefone"
				id="div-telefone">
				<label>Telefone</label>
				<the-mask
					id="input-telefone"
					ref="telefone"
					v-model="telefone"
					pattern="[0-9]*"
					inputmode="numeric"
					:mask="['(##) ####-####', '(##) #####-####']"
					:style="corPrimaria" ></the-mask>
			</div>

			<div id="userAgreement">
				<input
					v-model="agreement"
					type="checkbox" >

				<span>{{ agreementString }}</span>
			</div>

			<div class="box-buttons">
				<vue-recaptcha
					:sitekey="chaveRecaptchaPortal"
					class="vue-recaptcha"
					:loadRecaptchaScript="true"
					@verify="verified = true"></vue-recaptcha>
	
				<button-default
					:disable="!verified || !agreement"
					:color="corPrimaria"
					@click.native="next"
					text="Entrar" />

				<button-default
					v-if="esqueceuTelefone"
					:color="corPrimaria"
					@click.native="esqueceu"
					text="Esqueceu o seu telefone?" ></button-default>
			</div>
		</div>
	</div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapActions, mapMutations, mapState, createNamespacedHelpers } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import FormFields from "@/components/FormFields";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import { TheMask } from "vue-the-mask";

const { mapActions: mapActionsPassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "ConfirmBirthday",

	components: { ButtonDefault, FormFields, VueRecaptcha, TheMask },

	mixins: [globalMethods, mixins],

	data() {
		return {
			fields: [],
			identification: "",
			agreement: false,
			inputsValues: {},
			verified: false,
			telefone: ''
		};
	},

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa,
			token: (estado) => estado.token,
			lojas: (estado) => estado.passosRetornarDividas.lojas,
			nomeParceiroContratos: (estado) => estado.nomeParceiroContratos
		}),

		agreementString() {
			let telefone = this.parceiroConfig.sessoes?.customization != undefined && this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday != undefined &&
			this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarDataNasc ? ", data de nascimento" : "";

			let dataNascimento = this.parceiroConfig.sessoes?.customization != undefined && this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday != undefined &&
			this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarTelefone ? ", telefone" : "";

			if (this.parceiroConfig.emp_codigo == 350) {
				telefone = '';
				dataNascimento = '';
			}

			if (telefone != '') {
				telefone = telefone.replace(',', ' e');
			} else if (telefone == '' && dataNascimento != '') {
				dataNascimento = dataNascimento.replace(',', ' e');
			}

			return `Declaro estar ciente e autorizo a ${this.nomeParceiroContratos} a utilizar meu CPF${telefone}${dataNascimento} para pesquisar dívidas em meu nome`;
		},

		mensagemEmbaixoDoTitulo() {
			return this.parceiroConfig.sessoes.customization == undefined ||
					this.parceiroConfig.sessoes.customization.campos.confirmBirthday == undefined ||
					this.parceiroConfig.sessoes.customization.campos.confirmBirthday.flgValidarDataNasc ||
					this.parceiroConfig.sessoes.customization.campos.confirmBirthday.flgValidarTelefone ? "Confirme os seus dados para fazer seu login." : "Confirme que você não é um robô para fazer seu login.";
		},

		esqueceuTelefone() {
			return this.parceiroConfig.sessoes.customization == undefined ||
					this.parceiroConfig.sessoes.customization.campos.confirmBirthday == undefined ||
					this.parceiroConfig.sessoes.customization.campos.confirmBirthday.flgEsqueceuTelefone;
		},

		chaveRecaptchaPortal() {
			if (this.parceiroConfig.identificador === "aqui") {
				return "6Lf-sxAmAAAAAEAMKT4BGuREXmINn4jlC-jxpcbX"; 
			} else if (this.parceiroConfig.identificador.includes("resolvaomni")) {
				return "6LetqI8oAAAAAABevoxIB1HrUe2YWkLhGs9m_03z";
			} else if (this.parceiroConfig.identificador == 'resolvaagibank' || this.parceiroConfig.identificador == 'resolvaloft') {
				return "6LdvgukoAAAAAFI2-8M_DTFD_V3Te8rywZKAaEfF";
			} else if (
				this.parceiroConfig.identificador == "negociasenff" ||
				this.parceiroConfig.identificador == "negociandi" ||
				this.parceiroConfig.identificador == "negociacrediffato" ||
				this.parceiroConfig.identificador == "negociahapvida" || 
				this.parceiroConfig.identificador == "topnegocia" ||
				window.location.host == 'negociar.senff.com.br'
			) {
				return "6Lcd2rQpAAAAAABiBRByFn5pdaL-nYVMUVowVqLD";
			} else {
				return "6LcT3ZYaAAAAAFAwpV9WcdSbZUyuiEx9cC8xoF71";
			}
		}
	},

	created() {
		if (this.$route.params.identification) {
			this.identification = this.$route.params.identification;
			this.phoneNumber = this.$route.params.phoneNumber;
		} else {
			this.$router.push({ name: "LandPageParceiro" });
		}
	},

	mounted() {
		this.fields = [
			{
				variableName: "cpf_cnpj",
				fieldType: "TextFieldMask",
				label: "CPF/CNPJ",
				type: "text",
				cols: "12",
				maskFormat: "###.###.###-##",
				disabled: true,
				valueField: this.identification,
				required: true
			}
		];
		if (this.parceiroConfig.sessoes?.customization == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday == undefined ||
			this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarDataNasc) {

			var campoDN = {
				variableName: "nascimento",
				fieldType: "DateField",
				label: "Data nascimento",
				type: "text",
				cols: "12",
				required: true,
				plusEighteen: true
			};
			this.fields.push(campoDN);
		}
	},

	methods: {
		...mapMutations(["SET_RECEBER_LIGACAO", "SET_EMPRESAS"]),

		...mapActionsPassosRetornarDividas(["acaoPasso1ConsultarCpf"]),

		...mapActions(["action_atualizar_data_nascimento"]),

		dadosValidos() {
			if (this.parceiroConfig.sessoes?.customization == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarDataNasc)
				if (this.inputsValues.nascimento == "") {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Por favor",
						message: `Insira uma data de nascimento válida.`,
						styleMessage: "danger"
					});
					return false;
				}

			if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarTelefone)
				if (this.telefone == "" || this.telefone.length <= 9) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Por favor",
						message: `Insira um telefone válido.`,
						styleMessage: "danger"
					});
					return false;
				}

			return true;
		},

		receberCPFCNPJ() {
			this.acaoPasso1ConsultarCpf({
				pesCpfCnpj: this.removeMaskCharacters(this.identification)
			}).then(resposta => {
			
				if (resposta.data.retorno) {
			
					if (resposta.data.dados == null) {
						this.$router.push({
							name: "Register",
							params: {
								identification: this.removeMaskCharacters(this.identification)
							}
						});
						return -1;
			
					} else if (resposta.data.dados) {
						this.SET_RECEBER_LIGACAO(false);
			
						if (this.parceiroConfig.sessoes?.customization == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday == undefined ||
							this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarToken && this.parceiroConfig.identificador === "Senff") {

							this.$router.push({
								name: "VerifyToken",
								params: {
									fone: this.telefone
								}
							});
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);

						} else if (this.parceiroConfig.sessoes?.customization?.campos?.menuDebits?.flgPrefeituraMode) {
							this.$router.push({ name: "MyDebits" });

						} else {
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);
							this.$router.push({ name: "AccordionTabs" });
						}
					}

				} else if (!resposta.data.retorno && !resposta.data?.dados) {
					this.SET_LOADING(false);
					this.SET_LOADING_MESSAGE("");

					if (resposta.data.mensagem == "Erro ao consultar o usuário! 1") {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Data de nascimento incorreta",
							message: `Data de nascimento informada não condiz com a data do seu cadastro.`,
							styleMessage: "danger"
						});
						return -1;

					} else if (resposta.data.mensagem == "Erro ao consultar o usuário! 2") {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Telefone incorreto",
							message: `Telefone informada não condiz com o telefone do seu cadastro.`,
							styleMessage: "danger"
						});
						return -1;

					} else {

						if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgDesativaCadastro) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Usuario não encontrado",
								message: `Você não possui nenhuma dívida ou acordo cadastrado em nosso banco de dados.`,
								styleMessage: "danger"
							});

						} else {
							this.$router.push({
								name: "Register",
								params: {
									identification: this.removeMaskCharacters(this.identification)
								}
							});
						}
						return -1;
					}

				} else if (resposta.data.retorno && (resposta.data.dados === "CPF/CNPJ não encontrado!" || resposta.data.dados === null)) {
					this.SET_LOADING(false);
					this.SET_LOADING_MESSAGE("");

					if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgDesativaCadastro) {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Usuario não encontrado",
							message: `Você não possui nenhuma dívida ou acordo cadastrado em nosso banco de dados.`,
							styleMessage: "danger"
						});

					} else {
						this.$router.push({
							name: "Register",
							params: {
								identification: this.removeMaskCharacters(this.identification)
							}
						});
					}

				} else {
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Erro",
						message: `Ocorreu um erro ao tentar encontrar o seu CPF`,
						styleMessage: "danger"
					});
					return -1;
				}

			}).catch(_ => {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Erro",
					message: "Não foi possível achar seu CPF em nossos registros!",
					styleMessage: "danger"
				});
			});

			this.acaoSalvarEtapa({
				cpfCnpj: this.removeMaskCharacters(this.identification),
				esn_fk_ets_codigo: 8,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: 'Consultou o CPF/CNPJ',
				hora_agendamento: this.dataAtual,
				data_agendamento: this.horaAtual
			});
		},

		next() {

			if (!this.dadosValidos()) {
				return -1;
			}

			if (this.verified) {
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Confirmando os seus dados");
				this.receberCPFCNPJ();

			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Por favor",
					message: `Conclua a verificação de segurança`,
					styleMessage: "danger"
				});
				return -1;
			}
		},

		esqueceu() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Confirmando sua data de nascimento");
			this.$router.push({
				name: "ResetPhone",
				params: {
					identification: this.removeMaskCharacters(this.identification)
				}
			});

			this.SET_LOADING_MESSAGE("");
			this.SET_LOADING(false);
		}
	}
};
</script>

<style scoped lang="scss">
.page-birthday {
	min-height: 100vh;
	flex: 1;
	padding: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-birthday h3 {
	text-align: center;
	color: var(--secondaryColor);
	font-size: 32px;
	font-family: Montserrat-Bold;
	margin-bottom: 5px;
}

.page-birthday p {
	text-align: center;
	font-size: 14px;
	line-height: 24px;
	font-family: Roboto;
	font-weight: normal !important;
	margin-bottom: 30px;
}

.box-login {
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.vue-recaptcha {
	align-items: center;
	justify-content: center;
	display: flex;
}

.box-buttons {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	padding: 0 15px;
	gap: 20px;
}

#userAgreement {
	display: flex;
	align-items: center;

	input {
		background-color: #c9c9c9;
		border: 2px solid #bbbbbb;
		border-radius: 40px;
		border-bottom-color: transparent;
		border-left-color: transparent;
		height: 40px;
		left: -4px;
		outline: 0;
		top: -4px;
		width: 40px;
		box-sizing: border-box;
		animation: spinner-spin linear 2.5s infinite;
		animation-play-state: paused;
		transition-duration: 1s;
		margin-right: 6px;
	}
}

@media only screen and (max-width: 768px) {
	.page-birthday {
		align-items: flex-start;
	}
}

#div-telefone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	padding-left: 4%;
}

input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

.text-opacity {
	opacity: 0.7;
}

input.solid-color {
	background: var(--whiteColor);
}

input.border-default,
input.has-value {
	border: 0;
}

input.border-red {
	border: thin solid var(--dangerColor);
}

#input-telefone {
	width: 96%;
}
</style>
