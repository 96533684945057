<template>
	<div class="background">
		<div class="containerEmailSender">
			<div v-if="parceiro != 'uol'" class="iconBoxBack"
				@click="$emit('abrirModalEnvios')"
				:style="parceiro ? { color: parceiroConfig.estilo.corPrimaria } : { color: 'var(primaryColor' }">
				<i class="fas fa-chevron-left" />
				Voltar
			</div>
			<div class="content-page">
				<h3>Informe o e-mail pelo você deseja receber os dados da 2ª via:</h3>
				<TextField :valueField="email" :value.sync="email" :variableName="'email'" :type="'email'"
					:disabled="false" :placeholder="'Informe o e-mail pelo você deseja receber os dados da 2ª via'" />
				<button-default :color="corPrimaria" @click.native="send" text="Enviar boleto por e-mail" />
			</div>
		</div>
	</div>
</template>

<script>
	import TextField from "../../../../components/inputs/TextField";
	import ButtonDefault from "../../../../components/buttons/ButtonDefault";
	import globalMethods from "../../../../mixins/globalMethods";
	import mixins from "../../../../mixins/mixins";
	import { mapMutations, createNamespacedHelpers, mapState } from "vuex";
	import { ValidationObserver } from "vee-validate";

	const {
		mapActions: mapActionsPassosRetornarDividas,
		mapGetters: mapGettersPassosRetornarDividas
	} = createNamespacedHelpers("passosRetornarDividas");

	export default {
		name: "EmailSender",
		components: { TextField, ButtonDefault, ValidationObserver },
		mixins: [globalMethods, mixins],
		props: {
			linhaDigitavelSelecionada: {
				type: String,
				required: true,
			},
			pixSelecionado: {
				type: String,
				required: true,
			},
			lojCodigo: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				email: ""
			};
		},
		computed: {
			...mapState({
				parceiro: (state) => state.parceiro,
				parceiroConfig: (state) => state.parceiroConfig,
			}),
			tratamentoEmail() {
				let fullName = this.pessoa.split(" ");
				return this.parceiroConfig.sessoes.customization.campos.myAgreements.textEmailBoleto.replace(
					"[primeironome]",
					this.capitalizeFirstLetter(fullName[0])
				);
			}
		},
		methods: {
			...mapMutations(["CHANGE_MESSAGE_MODAL", "SET_LOADING", "SET_LOADING_MESSAGE"]),
			...mapActionsPassosRetornarDividas(["acaoEnviarSmsEmailSegundaViaSenff"]),
			...mapGettersPassosRetornarDividas(["getPesCpf"]),
			send() {
				if (
					!this.email ||
					!this.email.includes("@") ||
					!this.email.includes(".") ||
					this.email.charAt(this.email.length - 1) == "."
				) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um e-mail válido`,
						styleMessage: "danger"
					});
					return -1;
				}

				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Enviando por e-mail");

				this.acaoSalvarEtapa({
					cpfCnpj: this.cpfCnpj,
					esn_fk_ets_codigo: 101,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: "Enviou a segunda via por E-mail",
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual
				});

				this.acaoEnviarSmsEmailSegundaViaSenff({
					pesCpfCnpj: this.getPesCpf(),
					linhaDigitavel: this.linhaDigitavelSelecionada,
					pixCopiaECola: this.pixSelecionado,
					tipoEnvio: "email",
					enderecoEnvio: this.email,
					loj_codigo: this.lojCodigo,
				})
					.then((response) => {
						this.$emit("fecharModalEmail");
						if (response?.data?.retorno) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `E-mail enviado com sucesso.`,
								styleMessage: "success"
							});
						} else {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `Erro ao enviar o e-mail. Verifique se o seu e-mail está correto e tente novamente.`,
								styleMessage: "danger"
							});
							return -1;
						}
					})
					.catch(_ => {
						this.$emit("fecharModalEmail");
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao enviar o e-mail. Verifique se o seu e-mail está correto e tente novamente.`,
							styleMessage: "danger"
						});
						return -1;
					});
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.iconBoxBack {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: "Roboto Black";
		color: var(--primaryColor);
		text-transform: uppercase;
		cursor: pointer;
	}

	.iconBoxBack i {
		margin-right: 10px;
		font-size: 22px;
	}

	h3 {
		margin-top: 20px;
		color: #031b4e;
		font-family: "Roboto Black";
		text-transform: uppercase;
		font-size: 16px;
		text-align: center;
		background: #f5f6fa;
		padding: 10px 0;
	}

	.content-page h3 {
		margin-bottom: 20px;
	}

	.proposal_text {
		display: flex;
		width: 100%;
		margin-bottom: 20px;

		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
	}

	.containerEmailSender {
		width: 100%;
		max-width: 700px;
		box-sizing: border-box;
		padding: 30px;
		background: var(--whiteColor);
		border-radius: 40px;
		box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin-bottom: 40px;
	}

	.background {
		background-color: rgba(0, 0, 0, 0.9);
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 500;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
