<template>
	<div class="box-menu">

		<div class="box-menu-img" @click="$router.push({ name: 'LandPageParceiro' })">
			<div style="text-align: center; padding: 0 10px; @media only screen and (min-width: 990px) {width: 500px}">
				<img
					v-if="parceiro"
					key="portal-parceiro"
					:src="parceiroConfig.logo"
					alt="logo" />

				<img
					v-else
					key="portal-aquicob"
					src="@/assets/images/logo.png"
					alt="logo" />

				<h6
					v-show="!parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
					class="text-description">

					{{ textoEmbaixoDaLogo }}
				</h6>
			</div>
		</div>

		<div class="box-menu-options">

			<div
				v-if="returnRouterName === 'AccordionTabs' || parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
				key="portal-prefeitura"
				class="namePage" >

				<h5 style="text-transform: capitalize" v-if="parceiroConfig.identificador != 'resolvaomnidigital'">Olá, {{ pessoa.pesNome }}</h5>
				<div
					v-if="parceiroConfig.sessoes.customization.campos.landPage.flgIntegraRobo && pessoa.pesCpfCnpj != ''"
					class="btnIntegraRobo">

					<expanding-button
						:icon="'fab fa-whatsapp'"
						:title="'Fale com nosso atendimento'"
						:color="color"
						:menuSideButton="true"
						@click.native="connectWithBot()"></expanding-button>
				</div>
			</div>

			<div
				v-else
				key="portal-parceiro"
				class="namePage" >
				<h5 v-if="returnRouterName === 'MyDebits'">{{ nomePessoa }}<br>Minhas Dívidas</h5>
				<h5 v-else-if="returnRouterName === 'MyAgreements'">{{ nomePessoa }}<br>Meus Acordos</h5>
				<h5 v-else-if="returnRouterName === 'TalkToUs'">{{ nomePessoa }}<br>Fale Conosco</h5>
				<h5 v-else-if="returnRouterName === 'FAQ'">{{ nomePessoa }}<br>Dúvidas</h5>
				<div
					v-if="parceiroConfig.sessoes.customization.campos.landPage.flgIntegraRobo && pessoa.pesCpfCnpj != ''"
					class="btnIntegraRobo">
					<template>

						<expanding-button
							:icon="'fab fa-whatsapp'"
							:title="'Fale com nosso atendimento'"
							:color="color"
							:menuSideButton="true"
							@click.native="connectWithBot()"></expanding-button>
					</template>
				</div>

				<h6
					class="button-back"
					:style="corPrimaria"
					@click="voltar">

					<i class="fas fa-arrow-left" ></i>
					VOLTAR
				</h6>
			</div>

			<div class="box-bar">
				<template style="font-size: 24px; text-transform: uppercase; margin-bottom: 5px; text-align: center; color: #ffffff; font-family: 'Roboto Bold'">
					<h5 v-if="returnRouterName === 'MyDebits'"> Minhas Dívidas </h5>
					<h5 v-else-if="returnRouterName === 'MyAgreements'"> Meus Acordos </h5>
					<h5 v-else-if="returnRouterName === 'TalkToUs'"> Fale Conosco </h5>
					<h5 v-else-if="returnRouterName === 'FAQ'"> Dúvidas </h5>
				</template>
				<div
					v-if="parceiroConfig.sessoes.customization.campos.landPage.flgIntegraRobo && pessoa.pesCpfCnpj != ''" 
					class="btnIntegraRobo">
					<expanding-button
						:icon="'fab fa-whatsapp'"
						:title="'Fale com nosso atendimento'"
						:color="color"
						:menuSideButton="true"
						@click.native="connectWithBot()"></expanding-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import Template from "@/views/admin/Template";
import ExpandingButton from "@/components/buttons/ExpandingButton.vue";

import mixins from '@/mixins/mixins';

export default {
	name: "MenuDebits",

	components: { Template, ButtonDefault, ExpandingButton },

	mixins: [mixins],

	data() {
		return {
			showMobile: false,
			color: {
				"--color": "#ffffff",
				"--hoverColor": "#25D366"
			}
		};
	},

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa,
			parceiro: (estado) => estado.parceiroConfig.nome,
			parceiroConfig: (estado) => estado.parceiroConfig,
			corPrimaria: (estado) => estado.corPrimaria,
			cpfCnpj: (estado) => estado.cpfCnpj
		}),

		returnRouterName() {
			return this.$route.name;
		},

		textoEmbaixoDaLogo() {
			return this.parceiroConfig.sessoes.customization && this.parceiroConfig.sessoes.customization.campos.menuDebits && this.parceiroConfig.sessoes.customization.campos.menuDebits.textAbaixoLogo ?
					this.parceiroConfig.sessoes.customization.campos.menuDebits.textAbaixoLogo :
					"Negocie seus débitos com descontos e volte a ter crédito para comprar novamente!";
		},

		cpfFormatado() {
			return `${this.pessoa.pesCpfCnpj.substr(0, 3)}.${this.pessoa.pesCpfCnpj.substr(3, 3)}.${this.pessoa.pesCpfCnpj.substr(6, 3)}-${this.pessoa.pesCpfCnpj.substr(9)}`;
		},

		nomePessoa() {
			return this.parceiroConfig.identificador == 'resolvaomnidigital' ? '' : this.pessoa.pesNome;
		}
	},

	methods: {
		...mapMutations(["CHANGE_MESSAGE_MODAL"]),

		connectWithBot() {
			if (this.parceiroConfig.sessoes.contact.campos.whatsapp) {
				let janela = window.open(`https://api.whatsapp.com/send?phone=55${this.removeMaskCharacters(this.parceiroConfig.sessoes.contact.campos.whatsapp)}
											&text=Olá estou vindo do portal ${this.parceiro.toUpperCase()}. Meu nome é ${this.pessoa.pesNome} e o meu CPF/CPNJ é o ${this.cpfFormatado}.`, "_blank");
				janela.focus();
			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: 'Não foi possível encontrar o número de Whatsapp da assessoria! ',
					styleMessage: "danger"
				});
			}
		},

		voltar() {
			if (this.returnRouterName == 'MyDebits' || this.returnRouterName == 'MyAgreements' || this.returnRouterName == 'FAQ') {
				this.$router.push({name: 'AccordionTabs'});
			} else {
				this.$router.push({name: 'LandPageParceiro'});
			}
		}
	}
};
</script>

<style scoped lang="scss">
.box-menu {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex: 1;
	width: 100%;
	@media only screen and (min-width: 1270px) {
		min-height: 100vh;
	}
	@media only screen and (max-width: 1269px) {
		flex-direction: row;
		height: 130px;
	}
}
.box-menu-img {
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 30px;
	box-sizing: border-box;
	cursor: pointer;
	@media only screen and (max-width: 1269px) {
		padding: 0;
		height: 100px;
		width: 100vw;
		align-items: center;
		justify-content: flex-start;
	}
}

.box-menu-img img {
	max-width: 300px;
	max-height: 300px;
	@media only screen and (max-width: 1269px) {
		max-width: 200px;
		max-height: 150px;
		padding-left: 30px;
	}
}

.box-menu-img h6 {
	color: var(--whiteColor);
	margin-top: 30px;
	font-family: "Roboto Light";
	font-size: 24px;
	line-height: 32px;
	@media only screen and (max-width: 1269px) {
		display: none !important;
	}
}

.box-menu-options {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	h2 {
		color: var(--whiteColor);
		font-family: Montserrat-Regular;
		font-size: 18px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.slim {
	padding-bottom: 50px;
}

.slim,
.name-box-menu {
	@media only screen and (max-width: 1269px) {
		display: none;
	}
}

.box-bar,
.box-bar i {
	font-size: 32px !important;
	@media only screen and (min-width: 1270px) {
		display: none !important;
	}
}

.box-bar {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
	padding-right: 30px;
}

.box-bar i {
	color: #ffffff;
	font-size: 24px;
}

.namePage {
	width: 100%;
	padding: 0 0 30px 30px;
	@media only screen and (max-width: 1269px) {
		display: none !important;
	}
}

.namePage h5 {
	width: 100%;
	color: rgb(255, 255, 255);
	font-family: Montserrat-Regular;
	font-size: 18px;
	line-height: 70px;
}
.namePage h5 {
	width: 100%;
	color: rgb(255, 255, 255);
	font-family: Montserrat-Regular;
	font-size: 18px;
	line-height: 70px;
}

h6.text-description {
	color: rgb(255, 255, 255);
	font-family: Montserrat-Regular;
	font-size: 18px;
	padding: 0 20px;
}

.namePage h6 {
	color: rgb(255, 255, 255);
	font-family: Montserrat-Regular;
	font-size: 18px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.namePage h6 i {
	font-size: 18px;
	margin-right: 10px;
}

.button-back {
	background: var(--color);
	display: flex;
	padding: 25px;
	width: 100%;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	transition: all 0.2s ease-in-out;
}

h6.button-back i {
	font-size: 24px;
}

.button-back:hover {
	cursor: pointer;
	background: var(--hoverColor);
	transition: all 0.2s ease-in-out;
}

h6.button-back {
	color: #ffffff;
	font-family: Montserrat-Bold;
}

.menu-background-full {
	position: fixed;
	width: 100%;
	min-height: 100vh;
	left: 0;
	top: 0;
	background: var(--blackColor);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	z-index: 999;
	padding: 15vh 0;
}

.menu-background-full img {
	margin-bottom: 35px;
	width: 250px;
}

.menu-background-full i {
	font-size: 38px;
	color: var(--whiteColor);
	position: absolute;
	right: 5vw;
	top: 2vh;
}

.menu-background-full ul li {
	font-size: 32px;
	font-family: Cabin;
	margin: 40px 0;
	color: var(--whiteColor);
	cursor: pointer;
}

.menu-background-full li a {
	margin: 0;
	font-size: 32px;
	color: var(--whiteColor);
	font-family: Cabin;
	text-align: center;
}

.menu-background-full li.button-header {
	border: 4px solid #ffffff;
	padding: 15px 30px;
	border-radius: 27px;
	text-align: center;
	margin-top: 60px;
}
.btnIntegraRobo {
	align-self: end;
	margin-bottom: 30px;
	display: flex;
	border-bottom: 1px solid #28394e;
	justify-content: flex-end;
}
</style>
