<template>
	<div class="background">
		<div class="omni-box">
			<div
				class="omni-title"
				:style="parceiro ? { color: parceiroConfig.estilo.corPrimaria } : { color: 'var(primaryColor' }"
				@click="$emit('close')">

				<i
					class="fas fa-arrow-left"
					@click="voltar" ></i>

				<h6 v-if="motivoReceberLigacao == 'Saída'">Não vá embora!</h6>
				<h6 v-else-if="agenda">Agendar uma chamada:</h6>
				<h6 v-else>Escolha uma opção:</h6>
				<i v-if="saindo" @click="sair" class="fas fa-times" ></i>

			</div>
			<div v-if="!agenda" class="omni-page">
				<div
					v-if="parceiro == 'senff' && motivoReceberLigacao == 'Não reconheço essa dívida'"
					key="portal-senff-divida-nao-reconhecida"
					class="omni-text">

					<p>Essa dívida é referente ao Cartão Senff emitido pela {{ divida.loja.loj_fantasia }}. A fatura de xx/xx/xx não foi paga, a qual originou essa dívida.</p>
					<p>Se o pagamento já foi efetuado, o sistema tem um prazo de Xh para processá-lo, por favor aguarde.</p>
					<p>Se ainda assim não reconhecer esse débito, por favor escolha um canal de atendimento e fale com um de nossos especialistas.</p>
				</div>

				<div
					v-else-if="enviarBoletos">
					<p>Selecione por qual meio você deseja receber seus boletos</p>
				</div>

				<div
					v-else
					key="portal-parceiro-divida-nao-reconhecida"
					class="omni-text">

					<p>Queremos te ajudar a resolver sua pendência. Regularize seus débitos e volte a utilizar o seu cartão!</p>
					<p>Escolha o canal de sua preferência e fale AGORA com um de nossos especialistas</p>
				</div>

				<div class="row-buttons">
					<outline-button
						v-if="temChat && !enviarBoletos"
						:color="parceiroConfig.estilo.corPrimaria"
						:colorHover="'none'"
						:url="parceiroConfig.sessoes.omniChannel.campos.chatOmni"
						@click.native="salvarEtapa('chat', 43)"
						:text="'Chat'" :icon="'fas fa-comments fa-3x'" ></outline-button>

					<template v-if="temEmail || (parceiroConfig.identificador == 'Senff' && enviarBoletos)">
						<outline-button
							v-if="enviarBoletos"
							:icon="'fas fa-envelope-open-text fa-3x'"
							:color="corPrimaria"
							:colorHover="'none'"
							@click.native="salvarEtapa('email', 24)"
							:text="'E-mail'" ></outline-button>

						<outline-button
							v-else
							:icon="'fas fa-envelope-open-text fa-3x'"
							:color="'black'"
							:colorHover="'none'"
							:url="'mailto:' + parceiroConfig.sessoes.omniChannel.campos.emailOmni"
							@click.native="salvarEtapa('email', 42)"
							:text="'E-mail'" ></outline-button>
					</template>

					<outline-button
						v-if="temInstagram && !enviarBoletos"
						:icon="'fab fa-instagram fa-3x'"
						:color="'purple'"
						:colorHover="'linear-gradient(45deg,#833ab4,#c13584,#e1306c,#fd1d1d, orange, yellow)'"
						:url="parceiroConfig.sessoes.omniChannel.campos.instagramOmni"
						@click.native="salvarEtapa('instagram', 17)"
						:text="'Instagram'" ></outline-button>

					<outline-button
						v-if="temLinkedIn && !enviarBoletos"
						:icon="'fab fa-linkedin fa-3x'"
						:color="'#0a66c2'"
						:colorHover="'none'"
						:url="parceiroConfig.sessoes.omniChannel.campos.linkedinOmni"
						@click.native="salvarEtapa('linkedin', 18)"
						:text="'LinkedIn'"></outline-button>

					<outline-button
						v-if="temMessenger && !enviarBoletos"
						:icon="'fab fa-facebook-messenger fa-3x'"
						:color="'#2444d6'"
						:colorHover="'none'"
						:url="'http://m.me/' + parceiroConfig.sessoes.omniChannel.campos.messengerOmni"
						@click.native="salvarEtapa('messenger', 19)"
						:text="'Messenger'" ></outline-button>

					<outline-button
						v-if="temDiscadora && !enviarBoletos"
						:icon="'fas fa-phone fa-3x'"
						:color="'red'"
						:colorHover="'none'"
						@click.native="agenda = !agenda"
						:text="'Ligação'" ></outline-button>

					<outline-button
						v-if="temTelegram && !enviarBoletos"
						:icon="'fab fa-telegram fa-3x'"
						:color="'#0088cc'"
						:colorHover="'none'"
						:url="'https://t.me/' + parceiroConfig.sessoes.omniChannel.campos.telegramOmni"
						@click.native="salvarEtapa('telegram', 21)"
						:text="'Telegram'" ></outline-button>

					<outline-button
						v-if="temSkype && !enviarBoletos"
						:icon="'fab fa-skype fa-3x'"
						:color="'#00aff0'"
						:colorHover="'none'"
						:url="'skype:' + parceiroConfig.sessoes.omniChannel.campos.skypeOmni + '?chat'"
						@click.native="salvarEtapa('skype', 22)"
						:text="'Skype'" ></outline-button>

					<outline-button
						v-if="(temWhatsapp || usaWhatsappLinkCompleto) && !enviarBoletos"
						:icon="'fab fa-whatsapp fa-3x'"
						:color="'#4caf50'"
						:colorHover="'none'"
						:url="usaWhatsappLinkCompleto ? parceiroConfig.sessoes.omniChannel.campos.whatsappLinkOmni : whatappMensagemPronta"
						@click.native="salvarEtapa('whatsapp', 23)"
						:text="'Whatsapp'"></outline-button>

					<outline-button
						v-if="enviarBoletos"
						:icon="'fas fa-sms fa-3x'"
						:color="corPrimaria"
						:colorHover="'none'"
						@click.native="salvarEtapa('sms', 38)"
						:text="'SMS'"></outline-button>
				</div>
			</div>
					
			<div
				v-else
				class="omni-page schedule">

				<p>Que bom que você escolheu falar conosco! Vamos te ligar o mais rápido possível.</p>
				<label>Informe o número de telefone ou celular para contato:</label>

				<the-mask
					v-model="phone"
					pattern="[0-9]*"
					inputmode="numeric"
					:mask="['(##) #####-####']"
					class="themask-input"
					:placeholder="'Número de telefone'" ></the-mask>

				<button-default	
					style="margin: 10px 0"
					:color="corPrimaria"
					@click.native="send(false)"
					text="Receber uma ligação" ></button-default>

			</div>

			<SMSSender
				v-if="enviarBoletosPorSms"
				@close="fecharTudo()"
				:acordo="acordo"
				:paymentsSelected="parcelasSelecionadas" ></SMSSender>

			<email-sender
				v-if="enviarBoletosPorEmail"
				@close="fecharTudo()"
				:acordo="acordo"
				:paymentsSelected="parcelasSelecionadas" ></email-sender>

			<p id="erro">{{ mensagemErro }}</p>
		</div>
	</div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import { mapMutations, mapActions, mapState, createNamespacedHelpers } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import TextFieldMask from "@/components/inputs/TextFieldMask";
import DateField from "@/components/inputs/DateField";
import TimeField from "@/components/inputs/TimeField";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import OutlineButton from "@/views/documentDebits/components/OutlineButton";
import ScheduleCall from "./ScheduleCall";
import SMSSender from "@/views/documentDebits/MyAgreements/components/SMSSender.vue";
import EmailSender from "@/views/documentDebits/MyAgreements/components/EmailSender.vue";

const { mapState: mapStatePassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "ReceiveCall",

	components: { ButtonDefault, TextFieldMask, OutlineButton, ScheduleCall, DateField, TimeField, TheMask, SMSSender, EmailSender },

	mixins: [globalMethods, mixins],

	props: {
		contrato: {
			type: String,
			required: false
		},

		saindo: {
			type: Boolean,
			default: false
		},

		divida: {
			type: Object,
			required: false
		},

		enviarBoletos: {
			type: Boolean,
			required: false
		},

		acordo: {
			type: Object,
			required: false
		},

		parcelasSelecionadas: {
			type: Array,
			required: false
		}
	},

	data() {
		return {
			phone: "",
			date: "",
			time: "",
			agenda: false,
			mensagemErro: "",
			motivoNaoPodePagar: ["Desemprego", "Doença Afastamento Licença", "Juros Altos", "Discorda do Valor", "Redução Perda de receita", "Empréstimo realizado a terceiro",
							"Salário em atraso", "Prefiro negociar diretamente com a loja", "Não confio neste canal", "Esta dívida é muito antiga", "Outro motivo"],
			enviarBoletosPorEmail: false,
			enviarBoletosPorSms: false
		};
	},

	computed: {
		...mapState({
			empresas: (estado) => estado.empresas,
			receberLigacao: (estado) => estado.receberLigacao,
			motivoReceberLigacao: (estado) => estado.motivoReceberLigacao
		}),

		...mapStatePassosRetornarDividas({
			lojas: (estado) => estado.lojas,
			pessoa: (estado) => estado.pessoa
		}),

		computadoNaoPodePagar() {
			return this.motivoNaoPodePagar.includes(this.motivoReceberLigacao);
		},

		whatappMensagemPronta() {
			return `https://api.whatsapp.com/send?phone=55
				${this.removeMaskCharacters(this.parceiroConfig.sessoes.omniChannel.campos.whatsappOmni)}&text=%23Olá estou vindo do portal
				 ${this.parceiro.toUpperCase()}`;
		},

		usaWhatsappLinkCompleto() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.whatsappLinkOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.whatsappLinkOmni != undefined;
		},

		temChat() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.chatOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.chatOmni != undefined
		},

		temEmail() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.emailOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.emailOmni != undefined;
		},

		temInstagram() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.instagramOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.instagramOmni != undefined;
		},

		temLinkedIn() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.linkedinOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.linkedinOmni != undefined;
		},

		temMessenger() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.messengerOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.messengerOmni != undefined;
		},

		temDiscadora() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.flgdiscadoraOmni;
		},

		temTelegram() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.telegramOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.telegramOmni != undefined;
		},

		temSkype() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.skypeOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.skypeOmni != undefined;
		},

		temWhatsapp() {
			return this.parceiroConfig.sessoes.omniChannel.campos != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.whatsappOmni != null &&
				this.parceiroConfig.sessoes.omniChannel.campos.whatsappOmni != undefined;
		}
	},

	methods: {
		...mapActions(["action_solicitar_ligacao"]),
		...mapMutations(["SET_RECEBER_LIGACAO"]),

		voltar() {
			if (this.agenda) {
				this.agenda = false;
			} else this.SET_RECEBER_LIGACAO(false);
		},

		send() {
			if (this.phone.length < 11) {
				this.mensagemErro = "Informe um número de telefone válido.";
				return -1;

			} else {
				let metodo = "Receber uma ligacao";
				this.mensagemErro = "";
				const emp_codigo = this.parceiro == "aquicob" ? "" : this.parceiroConfig.emp_codigo;
				this.salvarEtapa(metodo, 20);
				this.action_solicitar_ligacao({
					pes_codigo: this.pessoa.pesCodigo,
					emp_codigo: emp_codigo,
					loj_codigo: this.divida != undefined || this.divida != null ? this.divida.loj_codigo : this.lojas[0].loj_codigo,
					telefone: this.removeMaskCharacters(this.phone)

				}).then((resposta) => {

					if (!resposta.data.retorno) {
						this.agenda = false;
						this.SET_RECEBER_LIGACAO(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao agendar uma nova chamada: ${resposta.data.mensagem}`,
							styleMessage: "danger"
						});
						return -1;
	
					} else if (resposta.data.mensagem.includes("Erro")) {
						this.SET_RECEBER_LIGACAO(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao agendar uma nova chamada`,
							styleMessage: "danger"
						});
						return -1;
	
					} else {
						this.SET_RECEBER_LIGACAO(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Sucesso",
							message: `Nova chamada agendada.`,
							styleMessage: "success"
						});
					}

				}).catch((_) => {
					this.SET_RECEBER_LIGACAO(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Erro ao agendar uma nova chamada`,
						styleMessage: "danger"
					});
					return -1;
				});
			}
		},

		salvarEtapa(metodo) {
			if (this.enviarBoletos) {
				switch (metodo) {
					case 'sms':
						this.enviarBoletosPorSms = true;
						this.enviarBoletosPorEmail = false;
						break;
					case 'email':
						this.enviarBoletosPorSms = false;
						this.enviarBoletosPorEmail = true;
						break;
				}
			}

			this.acaoSalvarEtapa({
				cpfCnpj: this.cpfCnpj,
				esn_fk_ets_codigo: this.computadoNaoPodePagar ? 16 : 15,
				emp_codigo: this.parceiroConfig.emp_codigo,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual,
				motivo_agendamento: "Entrou em contato por " + metodo.toUpperCase() + ", e o motivo foi: " + this.motivoReceberLigacao

			}).then((resposta) => {
				if (!resposta?.data?.retorno) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Contate o suporte, erro ao registrar etapa no banco de dados.`,
						styleMessage: "danger"
					});
					return -1;
				}
			});
		},

		sair() {
			this.$router.push({ name: "LandPageParceiro" });
		},

		fecharTudo() {
			this.enviarBoletosPorSms = false;
			this.enviarBoletosPorEmail = false;
			this.$emit('close');
		}
	},

	returnRouterName() {
		return this.$route.name;
	}
};
</script>

<style lang="scss" scoped>
.background {
	background-color: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.omni-box {
	background: #ffffff;
	width: 35em;
	max-height: 100vh;
	padding: 20px;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	text-align: center;
	@media only screen and (max-height: 900px) and (max-width: 1269px) {
		margin-top: 200px;
		overflow-y: scroll;
		max-height: 65vh;
	}
	overflow-x: visible;
	@media only screen and (max-width: 1640px) {
		width: 26em;
		overflow-y: scroll;
	}
}

.omni-title {
	display: grid;
	grid-template-columns: 20% 60% 20%;
	justify-content: space-between;
	text-transform: uppercase;
	cursor: pointer;
	margin-bottom: 7px;
	h6 {
		font-family: "Roboto Black";
		font-weight: normal;
		font-size: 1.7em;
		color: var(--blackColor);
		line-height: 28px;
	}
	i {
		font-size: 2em;
	}
}

.omni-page {
	width: 100%;
}

.omni-text {
	p {
		font-size: 1em;
		font-family: "Roboto";
		color: var(--blackColor);
		margin-bottom: 10px;
	}
}

.schedule {
	h2 {
		margin-top: 2em;
		font-family: "Roboto Black";
		font-weight: normal;
		font-size: 1.3em;
		color: var(--blackColor);
		line-height: 28px;
	}
	p {
		display: block;
		color: #1d1d1b;
		font-size: 14px;
		font-family: "Roboto";
	}
}

.row-buttons {
	margin-top: 10px;
	width: 100%;
	max-width: 440px;
	justify-content: center;
	align-content: center;
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
}
.box-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	* {
		padding-right: 10px;
	}
}

#erro {
	display: block;
	color: red;
	font-size: 14px;
	font-family: "Roboto";
}

@media only screen and (max-width: 768px) {
	.box-buttons {
		flex-direction: column;
	}
	.box-buttons button {
		margin: 5px 0;
	}
}
.themask-input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
	width: 100%;
}

label {
	margin: 10px 0;
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}
</style>
