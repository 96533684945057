<template>
    <div class="containerDebitDetail" :style="corPrimaria">
        <input id="campo_session_id" type="hidden" value="" />

        <div v-if="!enviarProposta && !receberLigacao && !contatoDigital && parceiro != 'uol'"
            class="row-options-buttons">

            <div style="width: 100%">

                <button-default v-if="!parceiroConfig.sessoes.customization.campos.myDebits.flgDesativarEnviarProposta"
                    :style="'width: 100%'" :color="parceiroConfigCorSecundariaCorPrimaria" :secondary-color="true"
                    @click.native="enviarProposta = true" text="Envie sua proposta"></button-default>
            </div>
        </div>

        <template v-if="enviarProposta || parceiro == 'uol'">
            <send-proposal :divida="divida" @close="enviarProposta = false"></send-proposal>
        </template>

        <template v-else-if="parceiro != 'uol'">

            <template v-if="parcelaSelected === null">

                <div id="div-data-primeiro-vencimento">
                    <h3>Data do primeiro vencimento</h3>

                    <input id="input-data-primeiro-vencimento" v-model="primeiroVencimento"
                        :style="{ color: parceiroConfig.estilo.corPrimaria, 'border-color': parceiroConfig.estilo.corPrimaria }"
                        :min="dataDeHoje" :max="dataLimite" type="date" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}">

                    <button-default @click.native="buscarNegociacoes" style="margin-top: 20px;"
                        :text="'Recalcular'"></button-default>
                </div>

                <h3>Qual a forma de pagamento?</h3>

                <div class="row-tabs">
                    <label class="tab left"
                        :style="aVistaOuAPrazo === 'A' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                        <input v-model="aVistaOuAPrazo" type="radio" id="a_vista" value="A" class="hide" checked>
                        À vista</label>

                    <label class="tab right"
                        :style="aVistaOuAPrazo === 'P' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                        <input v-model="aVistaOuAPrazo" type="radio" id="a_prazo" value="P" class="hide">
                        Parcelado</label>
                </div>

                <h3>Você quer pagar por?</h3>

                <div class="row-tabs">
                    <label class="tab left"
                        :style="typePayment === 'boleto' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                        <input id="boleto" v-model="typePayment" type="radio" value="boleto" class="hide">
                        Boleto</label>

                    <label v-if="!parceiroConfig.sessoes.customization.campos.myDebits.flgDesativarPix" class="tab"
                        :style="typePayment === 'pix' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                        <input id="pix" v-model="typePayment" type="radio" value="pix" class="hide">
                        PIX</label>

                    <label v-if="!parceiroConfig.sessoes.customization.campos.myDebits.flgDesativarCartaoCredito"
                        class="tab right"
                        :style="typePayment === 'cartao' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                        <input id="cartao" v-model="typePayment" type="radio" value="cartao" class="hide">
                        Cartão de Crédito</label>
                </div>

                <template v-if="typePayment == 'cartao' && parceiroConfig.identificador == 'mw'">
                    <h3>Forma do cartão</h3>

                    <div v-if="divida.loja.loj_cartao_de_credito_recorrente == 'S'" class="row-tabs">
                        <label class="tab"
                            :style="formaCartao === 'a_vista' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                            <input type="radio" value="a_vista" v-model="formaCartao" class="hide" /> à vista </label>

                        <label class="tab"
                            :style="formaCartao === 'parcelado' ? { color: parceiroConfig.estilo.corPrimaria, borderColor: parceiroConfig.estilo.corPrimaria } : 'var(primaryColor)'">
                            <input type="radio" value="parcelado" v-model="formaCartao" class="hide" /> recorrente
                        </label>
                    </div>
                    <div v-if="divida.loja.loj_cartao_de_credito_recorrente == 'S'">
                        <h1 style="margin-top: 20px;">{{ textoFormaCartao }}</h1>
                    </div>
                </template>

                <div v-if="typePayment == 'cartao' && parceiroConfig.identificador == 'mw'" style="margin-bottom: 20px;">
                    <h3>SELECIONE A BANDEIRA DO SEU CARTÃO</h3>
                    <select class="select-bandeira-cartao"
                        :style="{ 'border': '3px solid' + parceiroConfig.estilo.corPrimaria }" v-model="bandeiraCartao">
                        <option value="MASTERCARD">MASTERCARD</option>
                        <option value="VISA">VISA</option>
                        <option value="ELO">ELO</option>
                        <option value="HIPERCOB">HIPERCARD</option>
                        <option value="OTHER">OUTRA</option>
                    </select>
                </div>

                <div class="container-option-payment" style="margin-top: 30px;">
                    <h3>{{ tituloListaDePagamentos }}</h3>

                    <template v-if="lancamentosFuturos.aVista.length > 0">
                        <div v-if="lancamentosFuturosObrigatorios">
                            <label>Obs.: Lançamentos futuros inclusos no cálculo</label>
                        </div>

                        <div v-else class="checkBoxFuturePayment">
                            <input type="checkbox" v-model="somarFuturosInput" />
                            <label>Marque essa opção, caso queira incluir os lançamentos futuros a sua
                                negociação!</label>
                        </div>
                    </template>

                    <monthly-payment-card v-for="parcela in parcelas" :key="parcela.pra_codigo"
                        :typePayment="typePayment" :parcela="parcela" :divida="divida"
                        @click.native="clicouNoCard(parcela)"></monthly-payment-card>

                    <h6 class="message-empty" v-if="parcelas === 0">
                        {{ `Nenhuma opção de pagamento encontrada para a forma ${typePayment === "cartao" ? `cartão de
                        crédito` : "boleto"}.` }}

                    </h6>
                </div>
            </template>

            <details-monthly-payment v-else :parcela="parcelaSelected" :divida="divida" :tipoPagamento="typePayment"
                :lancamentosFuturos="lancamentosFuturos" :sessionIdNetCred="sessionIdNetCred" :formaCartao="formaCartao"
                @backOption="parcelaSelected = null"></details-monthly-payment>
        </template>
    </div>
</template>

<script>
    import { mapState, createNamespacedHelpers } from "vuex";
    import globalMethods from "@/mixins/globalMethods";
    import mixins from "@/mixins/mixins";

    import ButtonDefault from "@/components/buttons/ButtonDefault";
    import DetailsMonthlyPayment from "./DetailsMonthlyPayment";
    import Template from "@/views/admin/Template";
    import MonthlyPaymentCard from "./MonthlyPaymentCard";
    import SendProposal from "./SendProposal";
    import ReceiveCall from "./ReceiveCall";
    import DigitalContact from "./DigitalContact";

    const {
        mapState: mapStatePassosRetornarDividas,
        mapActions: mapActionsPassosRetornarDividas,
        mapGetters: mapGettersPassosRetornarDividas
    } = createNamespacedHelpers("passosRetornarDividas");

    let dataAtual = new Date();
    let dia = dataAtual.getDate() > 9 ? dataAtual.getDate() : `0${dataAtual.getDate()}`;
    let mes = dataAtual.getMonth() + 1 > 9 ? dataAtual.getMonth() + 1 : `0${dataAtual.getMonth() + 1}`;
    dataAtual = `${dataAtual.getFullYear()}-${mes}-${dia}`;

    export default {
        name: "PaymentOptionsDebit",

        components: {
            DigitalContact,
            ReceiveCall,
            SendProposal,
            MonthlyPaymentCard,
            Template,
            DetailsMonthlyPayment,
            ButtonDefault
        },

        mixins: [globalMethods, mixins],

        props: {
            divida: {
                type: Object,
                required: true
            },

            lancamentosFuturos: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                typePayment: "boleto",
                parcelaSelected: null,
                enviarProposta: false,
                receberLigacao: false,
                contatoDigital: false,
                sliderVModel: "",
                somarFuturosInput: false,
                parcelas: [],
                parcelasAvista: [],
                parcelasPrazo: [],
                aVistaOuAPrazo: "A",
                preCodigo: [],
                dataDeHoje: dataAtual,
                primeiroVencimento: dataAtual,
                primeiroVencimentoAnterior: dataAtual,
                sessionIdNetCred: "",
                bandeiraCartao: "MASTERCARD",
                porcentagemRecorrente: "2,99%",
                porcentagem1X: "2,49%",
                parcelamentoLinha2: "2X - 6X",
                porcentagemLinha2: "2,60%",
                parcelamentoLinha3: "7X - 12X",
                porcentagemLinha3: "2,99%",
                formaCartao: "a_vista",
                blur: false,
                enter: false
            };
        },

        computed: {
            ...mapState({
                escolhaFiltro: (estado) => estado.escolhaFiltro,
                corPrimaria: (estado) => estado.corPrimaria,
                corSecundaria: (estado) => estado.corSecundaria
            }),

            ...mapStatePassosRetornarDividas({
                botaoConsultarParcelamentosClicado: (estado) => estado.botaoConsultarParcelamentosClicado,
                pessoa: (estado) => estado.pessoa
            }),

            dataLimite() {
                let dataAgoraEmMilisegundos = Date.now();
                // if (this.somarFuturosInput) {
                //     let contrato = Object.keys(this.divida.contratos)[0];
                //     let parcela = Object.keys(this.divida.contratos[contrato].parcelas)[0];
                //     let parcelaPrincipal = this.divida.contratos[contrato].parcelas[parcela];

                //     let dataVencimentoPrincipal = new Date(`${parcelaPrincipal.par_vencimento.split('/').reverse().join('-')} 00:00:00`);
                //     let diferencaEntreDataAtualEDataPrincipal = Math.abs(dataAgoraEmMilisegundos - dataVencimentoPrincipal.getTime());
                //     let diferencaEntreDataAtualEDataPrincipalEmDias = Number.parseInt(diferencaEntreDataAtualEDataPrincipal / 86400000);

                //     if (diferencaEntreDataAtualEDataPrincipalEmDias > 14) {
                //         this.CHANGE_MESSAGE_MODAL({
                //             show: true,
                //             title: 'Erro de Negociação',
                //             message: 'Vencimento está fora do limite permitido de 14 dias.',
                //             styleMessage: 'danger'
                //         });
                //         return;
                //     }
                //     let dataMaximaLF = this.lancamentosFuturos.aVista[0].data_maxima_do_lf;

                //     if (dataMaximaLF != '' && dataMaximaLF != '0000-00-00' && dataMaximaLF != '0000-00-00 00:00:00' && dataMaximaLF != null && dataMaximaLF != undefined) {
                //         return dataMaximaLF.split(' ')[0];
                //     } else {
                //         this.CHANGE_MESSAGE_MODAL({
                //             show: true,
                //             title: 'Erro de Negociação',
                //             message: 'Vencimento está fora do limite permitido de 14 dias.',
                //             styleMessage: 'danger'
                //         });
                //         return;
                //     }
                // } else {
                    let milisegundosDataLimite = this.divida.loja.prazo_maximo_entrada_site * 86400000;
                    let dataLimite = new Date(dataAgoraEmMilisegundos + milisegundosDataLimite);
                    let mes = dataLimite.getMonth() + 1 > 9 ? dataLimite.getMonth() + 1 : `0${dataLimite.getMonth() + 1}`;
                    let dia = dataLimite.getDate() > 9 ? dataLimite.getDate() : `0${dataLimite.getDate()}`;
                    return `${dataLimite.getFullYear()}-${mes}-${dia}`;
                // }
            },

            lancamentosFuturosObrigatorios() {
                let obrigatorio = false;

                for (const lancamentoFuturo in this.lancamentosFuturos.aVista) {
                    if (lancamentoFuturo.lancamento_futuro_obrigatorio === "1") {
                        obrigatorio = true;
                    }
                }

                return obrigatorio;
            },

            textoFormaCartao() {
                if (this.formaCartao == 'a_vista') {
                    return "Modalidade normal de crédito, uma compra com a quantidade de parcelamentos escolhida";
                } else {
                    return "O pagamento recorrente é um método de pagamento em que o valor da parcela acordada é cobrado automaticamente do seu cartão de crédito em intervalos mensais e o limite total do acordo não compromete o limite do seu cartão de crédito.";
                }
            },

            tituloListaDePagamentos() {
                return this.divida.length === 0 ? "Esta opção de pagamento não está disponível!" : "Selecione o valor e a quantidade das parcelas que você quer pagar";
            }
        },

        watch: {
            aVistaOuAPrazo() {
                this.buscarNegociacoes();
            },
            somarFuturosInput() {
                this.buscarNegociacoes();
            },
            typePayment() {
                this.buscarNegociacoes();
            },
            bandeiraCartao() {
                this.buscarNegociacoes();
            },
            formaCartao() {
                this.buscarNegociacoes();
            }
        },

        created() {
            if (this.parceiroConfig.identificador == 'mw') {
                (function (a, b, c, d, e, f, g) {
                    a['CsdpObject'] = e; a[e] = a[e] || function () {
                        (a[e].q = a[e].q || []).push(arguments)
                    }, a[e].l = 1 * new Date(); f = b.createElement(c),
                        g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
                })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
                csdp('app', 'wsbv4xilxnqq3y08mb3b');
                csdp('outputsessionid', 'campo_session_id');
                this.sessionIdNetCred = document.getElementById('campo_session_id')

                csdp('inputsessionid', this.sessionIdNetCred);
            }
        },

        beforeMount() {
            this.retornarPreCodigoPorDivida();
            this.buscarNegociacoes();
        },

        mounted() {
            this.acaoSalvarEtapa({
                pes_cpf_cnpj: this.pessoa.pesCpfCnpj,
                pes_codigo: this.pessoa.pes_codigo,
                esn_fk_ets_codigo: 39,
                emp_codigo: this.parceiroConfig.emp_codigo,
                data_agendamento: this.dataAtual,
                hora_agendamento: this.horaAtual,
                motivo_agendamento: 'Vizualizou as parcelas da dívida'
            });

            if (this.parceiroConfig.identificador == 'mw') {
                (function (a, b, c, d, e, f, g) {
                    a['CsdpObject'] = e; a[e] = a[e] || function () {
                        (a[e].q = a[e].q || []).push(arguments)
                    }, a[e].l = 1 * new Date(); f = b.createElement(c),
                        g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
                })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
                csdp('app', 'seu_app');
                csdp('sessionid', this.sessionIdNetCred);
            }
        },

        methods: {
            ...mapGettersPassosRetornarDividas(["getPesCpf", "getTemSegundaViaSenff"]),

            ...mapActionsPassosRetornarDividas(["acaoPasso3ConsultarNegociacoes", "acaoPasso3ConsultarNegociacoesStage"]),

            retornarPreCodigoPorDivida() {
                let contratos = null;
                this.preCodigo = [];

                if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage) {
                    Object.values(this.divida.contratos).forEach(contrato => {
                        let preCodigos = contrato.parcelas.map(parcela => parcela.pre_codigo);
                        this.preCodigo.push(...preCodigos);
                    });
                } else {
                    if (this.aVistaOuAPrazo === "P") {
                        contratos = this.divida.contratos_aprazo;
                    } else if (this.aVistaOuAPrazo === "A") {
                        contratos = this.divida.contratos_avista;
                    }

                    for (const contrato in contratos) {
                        for (const parcela in contratos[contrato].parcelas) {
                            this.preCodigo.push(contratos[contrato].parcelas[parcela].pre_codigo);
                        }
                    }
                }
            },

            retornarPreCodigosPorLancamentosFuturos() {
                let parcelas = null;

                if (this.aVistaOuAPrazo === "P") {
                    parcelas = this.lancamentosFuturos.aPrazo;
                } else if (this.aVistaOuAPrazo === "A") {
                    parcelas = this.lancamentosFuturos.aVista;
                }

                for (const parcela in parcelas) {
                    this.preCodigo.push(parcelas[parcela].pre_codigo);
                }
            },

            verificarData() {
                const dataInput = new Date(this.primeiroVencimento);
                const dataDeHojeEmDate = new Date(this.dataDeHoje);
                const dataLimite = new Date(this.dataLimite);

                let ehMenorDoQueDataMinima = dataInput.getTime() < dataDeHojeEmDate.getTime() ? true : false;
                let ehMaiorDoQueDataMaxima = dataInput.getTime() > dataLimite.getTime() ? true : false;

                if (ehMenorDoQueDataMinima || ehMaiorDoQueDataMaxima) {
                    this.CHANGE_MESSAGE_MODAL({
                        show: true,
                        title: 'Erro',
                        message: `Data de vencimento passada é inválida, a data máxima possível é: ${this.formatarData(this.dataLimite)}`,
                        styleMessage: 'danger'
                    });
                    this.SET_LOADING(false);
                    this.SET_LOADING_MESSAGE('');
                    this.parcelas = [];
                    this.primeiroVencimento = this.dataLimite;
                    return false;
                } else {
                    this.primeiroVencimentoAnterior = this.primeiroVencimento;
                }
                return true;
            },

            buscarNegociacoes() {
                this.SET_LOADING(true);
                this.SET_LOADING_MESSAGE('Consultando os valores para negociação');
                this.retornarPreCodigoPorDivida();

                if (this.somarFuturosInput) {
                    this.retornarPreCodigosPorLancamentosFuturos();
                }
                if ((this.aVistaOuAPrazo === "A" || this.aVistaOuAPrazo === "P") && this.verificarData()) {
                    this.parcelas = [];
                    if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage && this.botaoConsultarParcelamentosClicado) {
                        this.acaoPasso3ConsultarNegociacoesStage({
                            lojCodigo: this.divida.loja.loj_codigo,
                            preCodigo: this.preCodigo,
                            primeiroVencimento: this.primeiroVencimento
                        }).then(resposta => {
                            resposta.calcular.pra_codigo.forEach(preAcordo => {
                                if (
                                    this.aVistaOuAPrazo === 'A' && Number.parseInt(preAcordo.pra_quantidade) > 0 ||
                                    this.aVistaOuAPrazo === 'P' && Number.parseInt(preAcordo.pra_quantidade) == 0
                                ) {
                                    return;
                                }

                                this.parcelas.push({
                                    pra_codigo: preAcordo.pra_codigo,
                                    pra_valor_entrada: preAcordo.pra_valor_entrada,
                                    pra_primeiro_vcto: preAcordo.pra_json_cobransaas.parcelas[0].dataVencimento,
                                    pra_quantidade: Number.parseInt(preAcordo.pra_quantidade),
                                    pra_valor_parcela: preAcordo.pra_valor_parcela,
                                    pagamento_avista_prazo: Number.parseInt(preAcordo.pra_quantidade) == 0 ? 'A' : 'P',
                                    valor_total_acordo: preAcordo.pra_json_cobransaas.valorTotal
                                });
                            });
                            this.SET_LOADING(false);
                            this.SET_LOADING_MESSAGE('');
                        }).catch(_ => {
                            this.SET_LOADING(false);
                            this.SET_LOADING_MESSAGE('');
                            this.CHANGE_MESSAGE_MODAL({
                                show: true,
                                title: "Erro",
                                message: "Não foi possível calcular as simulações, volte para a página inicial e tente novamente.",
                                styleMessage: "danger"
                            });
                            this.primeiroVencimento = this.dataDeHoje;
                        });
                    } else {
                        this.acaoPasso3ConsultarNegociacoes({
                            lojCodigo: this.divida.loja.loj_codigo,
                            preCodigo: this.preCodigo,
                            primeiroVencimento: this.primeiroVencimento,
                            pagamentoAvistaPrazo: this.aVistaOuAPrazo,
                            metodoPagamento: this.typePayment,
                            bandeiraCartao: this.bandeiraCartao,
                            formaCartao: this.formaCartao
                        }).then(resposta => {
                            this.SET_LOADING(false);
                            this.SET_LOADING_MESSAGE('');
                            if (!resposta.retorno) {
                                this.CHANGE_MESSAGE_MODAL({
                                    show: true,
                                    title: "Erro",
                                    message: resposta.mensagem,
                                    styleMessage: "danger"
                                });
                                this.primeiroVencimento = this.dataDeHoje;
                            } else {
                                if (resposta.dados.pre_acordo.length > 1 && resposta.dados.pre_acordo[0].pra_quantidade == 0) {
                                    resposta.dados.pre_acordo.shift();
                                }
                                this.parcelas.push(...resposta.dados.pre_acordo);
                            }
                        });
                    }
                }
            },

            clicouNoCard(parcela) {
                if (this.parceiroConfig.identificador == 'Senff' && this.getTemSegundaViaSenff() && parcela.pra_quantidade == 0 && this.somarFuturosInput == false) {
                    this.CHANGE_MESSAGE_MODAL({
                        show: true,
                        title: 'Segunda via',
                        message: 'Não é possível fazer acordo à vista tendo boleto da segunda via disponível',
                        styleMessage: 'info'
                    });

                    this.$emit('mostrarSegundaVia');
                    return;
                }

                if (this.verificarData()) {
                    this.parcelaSelected = parcela;
                }
            }
        }
    };
</script>

<style scoped>
    .containerDebitDetail {
        width: 100%;
        box-sizing: border-box;
        max-width: 1024px;
        padding: 30px;
        background: var(--whiteColor);
        border-radius: 40px;
        box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-bottom: 100px;
    }

    .header-debits {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        max-width: 1024px;
        cursor: pointer;
    }

    h3 {
        margin: 30px 0px;
        color: #031b4e;
        font-family: "Roboto Black";
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
    }

    .header-debits i {
        font-size: 48px;
        color: #031b4e;
    }

    .row-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-option-payment {
        padding: 30px 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .container-option-payment::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .container-option-payment::-webkit-scrollbar-thumb {
        background: #9a9a9a;
    }

    .row-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 0 0 0;
    }

    .tab {
        width: 100%;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        padding: 10px 0;
        border-bottom: 3px solid transparent;
        font-family: "Roboto Black";
        color: #9a9a9a;
    }

    .tab.active {
        border-bottom: 3px solid var(--color);
        font-family: "Roboto Black";
        color: var(--color);
    }

    .tab:hover {
        cursor: pointer;
    }

    .message-empty {
        text-align: center;
        padding: 40px 0;
        font-size: 14px;
        font-family: Roboto;
        color: #5b6987;
    }

    .row-options-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .row-options-buttons div {
        padding: 0 2px;
        margin-bottom: 5px;
    }

    .checkBoxFuturePayment {
        margin: 10px 0px;
        color: #D33724;
        font-family: "Roboto Black";
        /* text-transform: uppercase; */

        text-align: center;
    }

    .checkBoxFuturePayment label {
        font-size: 18px;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .row-options-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .row-options-buttons div {
            padding: 0px;
            margin-bottom: 5px;
            width: 100%;
        }
    }

    .opcoes_de_pagamento {
        display: flex;
        justify-content: space-around;
    }

    .opcoes_de_pagamento label {
        font-size: 16px;
        font-family: "Roboto Black";
        color: #031b4e;
    }

    #div-data-primeiro-vencimento {
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
    }

    #div-data-primeiro-vencimento label {
        color: #031b4e;
        font-family: "Roboto Black";
        font-size: 16px;
    }

    #input-data-primeiro-vencimento {
        border-radius: 25px;
        border: 3px solid #031b4e;
        width: 200px;
        margin-top: 20px;
        float: right;
        padding: 5px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }

    .hide {
        display: none;
    }

    .select-bandeira-cartao {
        border-radius: 20px;
        background-color: #fff;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
</style>