<template>
	<div class="background">
		<div class="containerSMSSender">
			<div v-if="parceiro != 'uol'" class="iconBoxBack" @click="$emit('abrirModalEnvios')"
				:style="parceiro ? { color: parceiroConfig.estilo.corPrimaria } : { color: 'var(primaryColor' }">
				<i class="fas fa-chevron-left" />
				Voltar
			</div>
			<div class="content-page">
				<h3>Informe o telefone celular que você deseja receber a linha digitável da 2ª via:</h3>
				<div class="proposal_text">
					<TextFieldMask :maskFormat="['(##) ####-####', '(##) #####-####']" :valueField="telefone"
						:variableName="'telefone'" :value.sync="telefone" :type="'phone'"
						:placeholder="'Informe o telefone celular que você deseja receber a linha digitável da 2ª via'" />
				</div>
				<button-default :color="corPrimaria" @click.native="send" text="Enviar dados 2ª via" />
			</div>
		</div>
	</div>
</template>

<script>
	import TextFieldMask from "../../../../components/inputs/TextFieldMask";
	import ButtonDefault from "../../../../components/buttons/ButtonDefault";
	import globalMethods from "../../../../mixins/globalMethods.vue";
	import mixins from "@/mixins/mixins";

	import { mapMutations, mapState, createNamespacedHelpers } from "vuex";

	const {
		mapGetters: mapGettersPassosRetornarDividas,
		mapActions: mapActionsPassosRetornarDividas
	} = createNamespacedHelpers("passosRetornarDividas");

	export default {
		name: "SMSSender",
		components: { TextFieldMask, ButtonDefault },
		mixins: [globalMethods, mixins],
		props: {
			pixSelecionado: {
				type: String,
				required: true,
			},
			linhaDigitavelSelecionada: {
				type: String,
				required: true,
			},
			lojCodigo: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				telefone: ""
			};
		},
		computed: {
			...mapState({
				parceiro: (state) => state.parceiro,
				parceiroConfig: (state) => state.parceiroConfig
			}),
			senff() {
				return this.parceiro == "senff";
			},
			lojaNome() {
				if (this.senff) {
					return this.acordo.detalhes &&
						this.acordo.detalhes[0].observacao_contrato &&
						this.acordo.detalhes[0].observacao_contrato <= 10
						? this.acordo.detalhes[0].observacao_contrato
						: "Senff";
				}
				return this.acordo.loj_razao_social;
			}
		},
		methods: {
			...mapMutations(["CHANGE_MESSAGE_MODAL", "SET_LOADING", "SET_LOADING_MESSAGE"]),
			...mapActionsPassosRetornarDividas(["acaoEnviarSmsEmailSegundaViaSenff"]),
			...mapGettersPassosRetornarDividas(["getPesCpf"]),
			send() {
				if (!this.removeMaskCharacters(this.telefone) || this.removeMaskCharacters(this.telefone).length != 11) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um celular válido`,
						styleMessage: "danger"
					});
				}
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Enviando por SMS");

				this.acaoSalvarEtapa({
					cpfCnpj: this.cpfCnpj,
					esn_fk_ets_codigo: 100,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: "Enviou a segunda via por SMS",
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual,
					loj_codigo: this.lojCodigo,
				});

				this.acaoEnviarSmsEmailSegundaViaSenff({
					pesCpfCnpj: this.getPesCpf(),
					linhaDigitavel: this.linhaDigitavelSelecionada,
					pixCopiaECola: this.pixSelecionado,
					tipoEnvio: "sms",
					enderecoEnvio: this.telefone,
					loj_codigo: this.lojCodigo
				})
					.then((response) => {
						this.$emit("fecharModalSms");
						if (response?.data?.retorno) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `SMS enviado com sucesso. Aguarde alguns segundos caso não tenha chego no seu celular .`,
								styleMessage: "success"
							});
						} else {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `Erro ao enviar o SMS.`,
								styleMessage: "danger"
							});
							return -1;
						}
					})
					.catch(_ => {
						this.$emit("fecharModalSms");
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao enviar o SMS.`,
							styleMessage: "danger"
						});
						return -1;
					});
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.iconBoxBack {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: "Roboto Black";
		color: var(--primaryColor);
		text-transform: uppercase;
		cursor: pointer;
	}

	.iconBoxBack i {
		margin-right: 10px;
		font-size: 22px;
	}

	h3 {
		margin-top: 20px;
		color: #031b4e;
		font-family: "Roboto Black";
		text-transform: uppercase;
		font-size: 16px;
		text-align: center;
		background: #f5f6fa;
		padding: 10px 0;
	}

	.content-page h3 {
		margin-bottom: 20px;
	}

	.proposal_text {
		display: flex;
		width: 100%;
		margin-bottom: 20px;

		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
	}

	.containerSMSSender {
		width: 100%;
		max-width: 700px;
		box-sizing: border-box;
		padding: 30px;
		background: var(--whiteColor);
		border-radius: 40px;
		box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin-bottom: 40px;
	}

	.background {
		background-color: rgba(0, 0, 0, 0.9);
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 500;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

</style>