<template>
	<div class="container-my-debits">
		<template>
			<div class="header-debits">

				<i v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
					:style="{ parceiroConfigCorSecundariaCorPrimaria }" class="fas fa-question"
					@click="$router.push({ name: 'FAQ' })"></i>

				<i v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
					:style="{ parceiroConfigCorSecundariaCorPrimaria }" class="fas fa-times"
					@click="$router.push({ name: 'LandPageParceiro' })"></i>

				<i v-else :style="{ parceiroConfigCorSecundariaCorPrimaria }" class="fas fa-times"
					@click="$router.push({ name: 'AccordionTabs' })"></i>

			</div>

			<warning-card v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
				key="portal-prefeitura"></warning-card>

			<div v-else key="portal-parceiro" style="display: flex; flex-direction: column; align-items: center">

				<h3 :style="{ parceiroConfigCorSecundariaCorPrimaria }">{{ tituloMinhasDividas }}</h3>

				<p v-if="parceiroConfig.identificador == 'Senff'" id="paragrafo-senff">
					As ofertas são temporárias e podem ser ajustadas a qualquer momento.
				</p>

				<p v-if="dividas && dividas.length > 0" key="portal-parceiro-com-dividas" class="message-sub">
					{{ mensagemQuantidadeDeDividas }}</p>

				<p v-else-if="parceiroConfig.sessoes.customization.campos.landPage.flgModoServico"
					key="portal-parceiro-sem-servicos" class="message-sub">
					Você não possui nenhuma solução disponível</p>

				<div v-else-if="possuiAcordo">
					<p class="message-sub">
						Vimos que você já possui acordo, clique em MEUS ACORDOS para verificar.
					</p>

					<button-default text="MEUS ACORDOS" @click.native="irParaPaginaDeAcordos"></button-default>
				</div>

				<p v-else key="portal-parceiro-sem-dividas" class="message-sub">{{ mensagemVoceNaoTemDividas }}</p>

				<div v-if="temPix" style="display: flex; flex-direction: column; align-items: center">

					<img src="@/assets/images/pix-106.svg" style="margin-bottom: 10px" />
					<p class="message-sub">Aceitamos pagamentos por PIX.</p>
					<p class="message-sub">Nossa chave PIX: {{
						parceiroConfig.sessoes.customization.campos.myDebits.textChavePix }}</p>
				</div>
			</div>

			<debit-card v-for="(divida, chave) in dividas" :key="chave" :divida="divida"></debit-card>
		</template>
	</div>
</template>

<script>
	import { mapState, createNamespacedHelpers } from "vuex";
	import mixins from "@/mixins/mixins";

	import DebitCard from "./components/DebitCard";
	import Template from "@/views/admin/Template";
	import WarningCard from "./components/WarningCard";
	import ButtonDefault from '../../../components/buttons/ButtonDefault.vue';

	const {
		mapActions: mapActionsPassosRetornarDividas,
		mapMutations: mapMutationsPassosRetornarDividas,
	} = createNamespacedHelpers("passosRetornarDividas");

	export default {
		name: "MyDebits",

		components: { Template, DebitCard, WarningCard, ButtonDefault },

		mixins: [mixins],

		data() {
			return {
				dividas: Array,
				qtdDividas: 0,
				possuiAcordo: false,
				arraySegundaVia: [],
			};
		},

		computed: {
			...mapState({
				empresas: (estado) => estado.empresas,
				lojas: (estado) => estado.passosRetornarDividas.lojas
			}),

			tituloMinhasDividas() {
				return this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebits ? this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebits : "Minhas Dívidas";
			},

			pluralOuSingular() {
				return this.parceiroConfig.sessoes.customization.campos.myDebits.subtextPanelMyDebits ?
					this.parceiroConfig.sessoes.customization.campos.myDebits.subtextPanelMyDebits : this.qtdDividas === 1 ?
						"dívida pendente" : "dívidas pendentes";
			},

			mensagemQuantidadeDeDividas() {
				if (typeof this.dividas === "object" && Object.keys(this.dividas).length > 0) {
					const dividas = Object.values(this.dividas);
					dividas.forEach(divida => {
						Object.entries(divida.contratos).forEach(([key, contrato]) => {
							this.qtdDividas += Object.keys(contrato.parcelas).length;
						});
					});
				}

				return `Você possui ${this.qtdDividas} ${this.pluralOuSingular}`;
			},

			mensagemVoceNaoTemDividas() {
				return this.parceiroConfig.sessoes.customization.campos.myDebits.subtextPanelMyDebitsNotFound ?
					this.parceiroConfig.sessoes.customization.campos.myDebits.subtextPanelMyDebitsNotFound : `Você não possui dívidas`;
			},

			temPix() {
				return this.parceiroConfig.sessoes.customization.campos.myDebits.textChavePix && this.parceiroConfig.sessoes.customization.campos.myDebits.textChavePix != "1";
			}
		},

		created() {
			this.$nextTick(function () {
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Carregando suas dívidas.");

				this.dividas = [];

				let lancamentoFuturo = this.parceiroConfig.identificador === "Senff" ? "S" : "N";
				if (this.lojas.length > 0) {
					this.lojas.forEach(async loja => {
						if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage) {
							await this.acaoPasso2ConsultarDividasStage({
								lojCodigo: loja.loj_codigo
							}).then(resposta => {
								this.SET_TEM_SEGUNDA_VIA_SENFF(false);
								if (resposta.parcelas.length == 0) {
									this.SET_LOADING(false);
									this.SET_LOADING_MESSAGE('');
									return;
								}

								const contratos = {};
								let totalCorrigidoDividas = 0;
								if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgAgruparContratos) {
									resposta.parcelas.forEach(parcela => {
										if (contratos[parcela.contrato] == undefined) {
											contratos[parcela.contrato] = {
												totalCorrigido: 0,
												parcelas: []
											};
										}

										contratos[parcela.contrato].parcelas.push({
											fase: parcela.diasAtraso,
											par_vencimento: this.formatarData(parcela.dataVencimento),
											par_prestacao: parcela.numeroParcela,
											original: parcela.valorPrincipal,
											corrigido: parcela.valorAtual,
											pre_codigo: parcela.pre_codigo
										});

										totalCorrigidoDividas += Number.parseFloat(parcela.valorAtual);
									});

									this.dividas.push({
										contratos,
										loja,
										totalCorrigido: totalCorrigidoDividas
									});
								} else {
									resposta.parcelas.forEach(parcela => {
										if (contratos[parcela.contrato] == undefined) {
											contratos[parcela.contrato] = {
												totalCorrigido: 0,
												parcelas: []
											};
										}

										contratos[parcela.contrato].parcelas.push({
											fase: parcela.diasAtraso,
											par_vencimento: this.formatarData(parcela.dataVencimento),
											par_prestacao: parcela.numeroParcela,
											original: parcela.valorPrincipal,
											corrigido: parcela.valorAtual,
											pre_codigo: parcela.pre_codigo
										});

										contratos[parcela.contrato].totalCorrigido += Number.parseFloat(parcela.valorAtual);
									});

									Object.entries(contratos).forEach(([chave, contrato]) => {
										let valorCorrigidoAPrazo = 0, valorCorrigido = 0;

										if (['resolvaloft', 'resolvaomni', 'resolvaomnidigital'].includes(this.parceiroConfig.identificador)) {
											Object.values(contrato.parcelas).forEach(parcela => {
												valorCorrigido += parcela.par_saldo;
											})
										} else {
											Object.values(contrato.parcelas).forEach(parcela => {
												valorCorrigido += parcela.corrigido;
											})
										}

										if (contratos_aprazo !== undefined) {
											Object.values(contratos_aprazo[chave].parcelas).forEach(parcela => {
												valorCorrigidoAPrazo += parcela.corrigido;
											})
										}

										this.dividas.push({
											contratos: { [chave]: contrato },
											loja,
											totalCorrigido: valorCorrigido,

											contratos_avista: { [chave]: contrato },
											total_corrigido_avista: valorCorrigido,

											contratos_aprazo: { [chave]: contratos_aprazo[chave] },
											total_corrigido_aprazo: valorCorrigidoAPrazo,

											integra_senff: resposta.dados.a_vista.integra_senff,
											nome_loja_senff: contrato.con_nome_loja,
										})

									});
								}
								this.SET_LOADING(false);
								this.SET_LOADING_MESSAGE('');
							}).catch((_) => {
								this.SET_LOADING(false);
								this.SET_LOADING_MESSAGE('');
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Erro",
									message: "Erro ao carregar as sua dívidas, volte para o menu inicial!",
									styleMessage: "danger"
								});
							});
						} else {
							await this.acaoPasso2ConsultarDividas({
								lojCodigo: loja.loj_codigo,
								lancamentoFuturo: lancamentoFuturo
							}).then(resposta => {
								if (resposta.retorno && resposta.dados) {
									this.SET_ARRAY_SEGUNDA_VIA_SENFF([]);
									this.SET_TEM_SEGUNDA_VIA_SENFF(false);
									if (resposta.dados.a_vista.loj_tipo_agrupamento_nagociacao == "contratos_iguais") {

										let contratos = resposta.dados.a_vista.contratos;
										let contratos_aprazo = resposta.dados.a_prazo.contratos;

										Object.entries(contratos).forEach(([chave, contrato]) => {
											let valorCorrigidoAPrazo = 0, valorCorrigido = 0;

											if (['resolvaloft', 'resolvaomni', 'resolvaomnidigital'].includes(this.parceiroConfig.identificador)) {
												Object.values(contrato.parcelas).forEach(parcela => {
													valorCorrigido += parcela.par_saldo;
												})
											} else {
												Object.values(contrato.parcelas).forEach(parcela => {
													if (parcela.pix_copia_cola_segunda_via != '') {
														this.ADD_ARRAY_SEGUNDA_VIA_SENFF(
															{
																'par_prestacao': parcela.par_prestacao,
																'par_vencimento': parcela.par_vencimento,
																'par_valor': parcela.par_valor,
																'pix_copia_cola_segunda_via': parcela.pix_copia_cola_segunda_via,
																'linha_digitavel_segunda_via': parcela.linha_digitavel_segunda_via,
																'contrato': contrato.con_contrato
															}
														);

														this.SET_TEM_SEGUNDA_VIA_SENFF(true);
													}

													valorCorrigido += parcela.corrigido;
												})
											}

											if (contratos_aprazo !== undefined) {
												Object.values(contratos_aprazo[chave].parcelas).forEach(parcela => {
													valorCorrigidoAPrazo += parcela.corrigido;
												})
											}

											this.dividas.push({
												contratos: { [chave]: contrato },
												loja,
												totalCorrigido: valorCorrigido,

												contratos_avista: { [chave]: contrato },
												total_corrigido_avista: valorCorrigido,

												contratos_aprazo: { [chave]: contratos_aprazo[chave] },
												total_corrigido_aprazo: valorCorrigidoAPrazo,

												integra_senff: resposta.dados.a_vista.integra_senff,
												nome_loja_senff: contrato.con_nome_loja,
											})

										});

									} else {
										let valorCorrigido = 0;
										if (['resolvaloft', 'resolvaomni', 'resolvaomnidigital'].includes(this.parceiroConfig.identificador)) {
											const contratos = Object.keys(resposta.dados.a_vista.contratos);
											Object.values(resposta.dados.a_vista.contratos).forEach((contrato, posicao) => {
												Object.values(contrato.parcelas).forEach(parcela => {
													if (parcela.pix_copia_cola_segunda_via != '') {
														this.ADD_ARRAY_SEGUNDA_VIA_SENFF(
															{
																'par_prestacao': parcela.par_prestacao,
																'par_vencimento': parcela.par_vencimento,
																'par_valor': parcela.par_valor,
																'pix_copia_cola_segunda_via': parcela.pix_copia_cola_segunda_via,
																'linha_digitavel_segunda_via': parcela.linha_digitavel_segunda_via,
																'contrato': contratos[posicao],
															}
														);

														this.SET_TEM_SEGUNDA_VIA_SENFF(true);
													}

													valorCorrigido += parcela.par_saldo;
												});
											});
										}

										this.dividas.push({
											contratos: resposta.dados.a_vista.contratos,
											loja,
											totalCorrigido: valorCorrigido === 0 ? resposta.dados.a_vista.total_corrigido : valorCorrigido,

											tipo_necociacao: resposta.dados.loj_tipo_agrupamento_nagociacao,
											contratos_aprazo: resposta.dados.a_prazo.contratos,
											total_corrigido_aprazo: resposta.dados.a_prazo.total_corrigido,
											contratos_avista: resposta.dados.a_vista.contratos,
											total_corrigido_avista: resposta.dados.a_vista.total_corrigido,
										});
									}
								}

								if (resposta.retorno === false && resposta.mensagem == 'Acordo Ativo') {
									this.possuiAcordo = true;
								}

								this.CHANGE_MESSAGE_MODAL({
									show: false,
									title: "",
									message: "",
									styleMessage: ""
								});
								this.SET_LOADING(false);
								this.SET_LOADING_MESSAGE('');
							}).catch((_) => {
								this.SET_LOADING(false);
								this.SET_LOADING_MESSAGE('');
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Erro",
									message: "Erro ao carregar as sua dívidas, volte para o menu inicial!",
									styleMessage: "danger"
								});
							});
						}

					});
				} else {
					this.SET_LOADING(false);
					this.SET_LOADING_MESSAGE('');
				}
			});
		},

		methods: {
			...mapActionsPassosRetornarDividas(["acaoPasso2ConsultarDividas", "acaoPasso2ConsultarDividasStage"]),

			...mapMutationsPassosRetornarDividas(["SET_TEM_SEGUNDA_VIA_SENFF", "ADD_ARRAY_SEGUNDA_VIA_SENFF", "SET_ARRAY_SEGUNDA_VIA_SENFF"]),

			getSubdominio() {
				return window.location.host.split(".").length > 2;
			},

			formatarData(data) {
				if (data.indexOf('T') != -1) {
					data = data.substring(0, data.indexOf('T'));
				}

				let arrayData = data.split("-");
				let dia = arrayData[2].length == 2 ? arrayData[2] : `0${arrayData[2]}`;
				let mes = arrayData[1].length == 2 ? arrayData[1] : `0${arrayData[1]}`;
				return `${dia}/${mes}/${arrayData[0]}`;
			},

			irParaPaginaDeAcordos() {
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Carregando os seus acordos.");
				this.$router.push({ name: "MyAgreements" });
			}
		}
	};
</script>

<style scoped>
	.container-my-debits {
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f3f5f9;
		flex: 1;
		box-sizing: border-box;
	}

	.header-debits {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		box-sizing: border-box;
		width: 100%;
		max-width: 1024px;
		cursor: pointer;
	}

	h3 {
		color: var(--backgroundColor);
		font-family: Montserrat-Bold;
		font-size: 28px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.header-debits i {
		font-size: 48px;
		margin: 10px 20px;
		color: var(--backgroundColor);
	}

	.message-sub {
		color: #5b6987;
		font-size: 14px;
		margin-bottom: 10px;
		text-align: justify;
	}

	#paragrafo-senff {
		color: var(--backgroundColor);
		font-size: 12px;
		margin-bottom: 10px;
	}
</style>
