<template>
    <div class="containerSegundaViaBoleto">
        <template v-if="arraySegundaVia.length > 0">
            <h4>Escolha um dos boletos</h4>
            <p id="paragrafo-senff">Saldo financiado e encargos contratuais serão acrescentados na fatura do mês
                seguinte.</p>
            <table class="table-parcelas">
                <tr>
                    <th>Vencimento da fatura</th>
                    <th>Linha Digitável</th>
                    <th>PIX</th>
                    <th>Enviar</th>
                </tr>
                <tr :key="i" v-for="(segundaVia, i) in arraySegundaVia">
                    <td>{{ segundaVia.par_vencimento }}</td>
                    <td class="celula-linha-digitavel"><span class="texto-linha-digitavel">{{
                        segundaVia.linha_digitavel_segunda_via }}</span><i
                            :style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
                            class="fas fa-copy"
                            @click="copiarLinhaDigitavel(segundaVia.linha_digitavel_segunda_via)"></i></td>
                    <td><i class="fas fa-qrcode"
                            :style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
                            @click="abrirModalQrCode(segundaVia.pix_copia_cola_segunda_via)"></i></td>
                    <td><i :style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
                            class="fas fa-external-link-alt"
                            @click="abrirModalSelecionarModoEnvio(segundaVia.pix_copia_cola_segunda_via, segundaVia.linha_digitavel_segunda_via)"></i>
                    </td>
                </tr>
            </table>
            <tela-qr-code-pix v-if="mostrarModalQrCode" :pixCopiaECola="pixSelecionado" :segundaVia="true"
                @fechar="mostrarModalQrCode = false"></tela-qr-code-pix>

            <div class="background" v-if="mostrarModalMeiosEnvio">
                <div class="omni-box">
                    <div class="omni-title">
                        <p></p>
                        <h6>Envio 2ª via</h6>
                        <i @click="mostrarModalMeiosEnvio = false" class="fas fa-times fa-3x"></i>
                    </div>
                    <div class="button-row omni-page">
                        <p style="margin-bottom: 18px;">Selecione o modo que deseja enviar o Pix Copia e Cole e a linha
                            digitável</p>
                        <outline-button :icon="'fas fa-envelope-open-text fa-3x'" :colorHover="'none'"
                            :color="corPrimaria" @click.native="abrirModalEnvioMeio('email')"
                            :text="'E-mail'"></outline-button>
                        <outline-button :icon="'fas fa-sms fa-3x'" :color="corPrimaria" :colorHover="'none'"
                            @click.native="abrirModalEnvioMeio('sms')" :text="'SMS'"></outline-button>
                    </div>
                </div>
            </div>

            <SMSSender v-if="meioSelecionado == 'sms'" :pixSelecionado="pixSelecionado"
                :linhaDigitavelSelecionada="linhaDigitavelSelecionada" :lojCodigo="lojCodigo"
                @abrirModalEnvios="abrirModalEnvio()" @fecharModalSms="fecharModalFormaEnvio()"></SMSSender>
            <email-sender v-if="meioSelecionado == 'email'" :pixSelecionado="pixSelecionado"
                :linhaDigitavelSelecionada="linhaDigitavelSelecionada" :lojCodigo="lojCodigo"
                @abrirModalEnvios="abrirModalEnvio()" @fecharModalEmail="fecharModalFormaEnvio()"></email-sender>
        </template>
        <template v-else>
            <h4>Você não possui 2ª via de fatura disponível neste contrato</h4>
        </template>
    </div>
</template>

<script>
    import globalMethods from "@/mixins/globalMethods";
    import mixins from "@/mixins/mixins";
    import { mapGetters, createNamespacedHelpers } from "vuex";

    import TelaQrCodePix from "../../MyAgreements/components/TelaQrCodePix.vue";
    import ReceiveCall from "./ReceiveCall.vue";
    import OutlineButton from '../../components/OutlineButton.vue';
    import SMSSender from "./SMSSender.vue";
    import EmailSender from './EmailSender.vue';

    const {
        mapGetters: mapGettersPassosRetornarDividas,
        mapActions: mapActionsPassosRetornarDividas,
    } = createNamespacedHelpers("passosRetornarDividas");

    export default {
        name: 'SegundaViaBoleto',

        mixins: [globalMethods, mixins],

        props: {
            contrato: {
                type: String,
                required: true,
            },
            lojCodigo: {
                type: Number,
                required: true,
            },
        },

        components: {
            TelaQrCodePix,
            ReceiveCall,
            OutlineButton,
            SMSSender,
            EmailSender,
        },

        data() {
            return {
                arraySegundaVia: [],
                pixSelecionado: "",
                mostrarModalEnvio: false,
                meioSelecionado: "",
                linhaDigitavelSelecionada: "",
                mostrarModalMeiosEnvio: false,
                mostrarModalQrCode: false
            }
        },

        methods: {
            ...mapGetters(["getPesCpf"]),

            ...mapGettersPassosRetornarDividas(["getArraySegundaViaSenff"]),

            ...mapActionsPassosRetornarDividas(["acaoEnviarSmsEmailSegundaViaSenff"]),

            abrirParcela(link) {
                window.open(link, '_blank');
            },

            abrirModalEnvioMeio(meio) {
                this.meioSelecionado = meio;
                this.mostrarModalMeiosEnvio = false;
            },

            abrirModalEnvio() {
                this.meioSelecionado = "";
                this.mostrarModalMeiosEnvio = true;
            },

            selecionarDadosSegundaVia(pixCopiaECola, linhaDigitavel) {
                this.pixSelecionado = pixCopiaECola;
                this.linhaDigitavelSelecionada = linhaDigitavel;
            },

            abrirModalSelecionarModoEnvio(pixCopiaECola, linhaDigitavel) {
                this.selecionarDadosSegundaVia(pixCopiaECola, linhaDigitavel);
                this.mostrarModalMeiosEnvio = true;
            },

            fecharModalFormaEnvio() {
                this.meioSelecionado = '';
                this.pixSelecionado = '';
                this.linhaDigitavelSelecionada = '';
            },

            abrirModalQrCode(pixCopiaECola) {
                this.pixSelecionado = pixCopiaECola;
                this.mostrarModalQrCode = true;
            },

            copiarLinhaDigitavel(linhaDigitavel) {
                this.acaoSalvarEtapa({
                    cpfCnpj: this.cpfCnpj,
                    esn_fk_ets_codigo: 102,
                    emp_codigo: this.parceiroConfig.emp_codigo,
                    data_agendamento: this.dataAtual,
                    hora_agendamento: this.horaAtual,
                    motivo_agendamento: 'Copiou a Linha Digitável'
                });

                navigator.clipboard.writeText(linhaDigitavel);
                alert('Linha Digitável copiada para sua área de transferência');
            }
        },

        mounted() {
            this.arraySegundaVia = this.getArraySegundaViaSenff().filter((segundaVia) => {
                return segundaVia.contrato == this.contrato;
            });
        }
    }
</script>

<style lang="scss" scoped>
    .containerSegundaViaBoleto {
        width: 100%;
        box-sizing: border-box;
        max-width: 1024px;
        padding: 30px;
        background: var(--whiteColor);
        border-radius: 40px;
        box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-bottom: 100px;
    }

    .header-debits {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        max-width: 1024px;
        cursor: pointer;
    }

    h3 {
        margin-bottom: 30px;
        color: #031b4e;
        font-family: "Roboto Black";
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
    }

    .header-debits i {
        font-size: 48px;
        color: #031b4e;
    }

    .infos-row {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .infos {
        padding: 10px 20px;
    }

    .infos h6 {
        color: #5b6987;
        font-family: "Roboto";
        font-size: 11px;
        margin-bottom: 3px;
    }

    .infos h2 {
        color: #031b4e;
        font-family: "Roboto Black";
        font-size: 14px;
    }

    h4 {
        color: #031b4e;
        font-family: "Roboto Black";
        text-transform: uppercase;
        font-size: 16px;
        background: #f5f6fa;
        padding: 10px;
        flex: 1;
        text-align: center;
    }

    .table-parcelas {
        width: 100%;
        text-align: center;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .table-parcelas td {
        color: #5b6987;
        font-family: "Roboto";
        font-size: 14px;
        height: 30px;
    }

    .parcelas-box {
        max-height: 400px;
        overflow-y: auto;
    }

    .background {
        background-color: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .omni-box {
        background: #ffffff;
        width: 35em;
        max-height: 100vh;
        padding: 20px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;

        @media only screen and (max-height: 900px) and (max-width: 1269px) {
            margin-top: 200px;
            overflow-y: scroll;
            max-height: 65vh;
        }

        overflow-x: visible;

        @media only screen and (max-width: 1640px) {
            width: 26em;
            overflow-y: scroll;
        }
    }

    .omni-title {
        display: grid;
        grid-template-columns: 10% 80% 10%;
        justify-content: space-between;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 7px;

        h6 {
            font-family: "Roboto Black";
            font-weight: normal;
            font-size: 1.7em;
            color: var(--blackColor);
            line-height: 28px;
        }

        i {
            font-size: 2em;
        }
    }

    .omni-page {
        width: 100%;
    }

    .omni-text {
        p {
            font-size: 1em;
            font-family: "Roboto";
            color: var(--blackColor);
            margin-bottom: 10px;
        }
    }

    .schedule {
        h2 {
            margin-top: 2em;
            font-family: "Roboto Black";
            font-weight: normal;
            font-size: 1.3em;
            color: var(--blackColor);
            line-height: 28px;
        }

        p {
            display: block;
            color: #1d1d1b;
            font-size: 14px;
            font-family: "Roboto";
        }
    }

    .row-buttons {
        margin-top: 10px;
        width: 100%;
        max-width: 440px;
        justify-content: center;
        align-content: center;
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    .box-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        * {
            padding-right: 10px;
        }
    }

    #erro {
        display: block;
        color: red;
        font-size: 14px;
        font-family: "Roboto";
    }

    @media only screen and (max-width: 768px) {
        .box-buttons {
            flex-direction: column;
        }

        .box-buttons button {
            margin: 5px 0;
        }
    }

    .themask-input {
        margin: 5px 0;
        height: 46px;
        border-radius: 10px;
        background: var(--whiteColor);
        padding: 0 10px;
        border: 1px solid #878785 !important;
        color: #1d1d1b;
        font-family: "Roboto Black";
        font-size: 16px;
        width: 100%;
    }

    label {
        margin: 10px 0;
        display: block;
        color: #1d1d1b;
        font-size: 14px;
        font-family: "Roboto";
    }

    #paragrafo-senff {
        color: var(--backgroundColor);
        font-size: 14px;
        margin: 15px 0px;
        text-align: center;
    }

    .fas:hover {
        cursor: pointer;
    }

    @media (min-width: 621px) {
        .celula-linha-digitavel>i {
            margin-left: 20px;
        }

        .table-parcelas th {
            color: #031b4e;
            font-family: "Roboto Black";
            font-size: 14px;
            height: 50px;
        }
    }

    @media (max-width: 620px) {
        .texto-linha-digitavel {
            display: none;
        }

        .celula-linha-digitavel {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .celula-linha-digitavel>i {
            margin-left: 0;
        }

        th {
            color: #031b4e;
            font-family: "Roboto";
            font-size: 13px;
            height: 50px;
        }
    }
</style>